@use 'include-media' as *;
@use '../general/variables' as *;

.form-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .form-content-container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .form-fields-set {
      display: inherit;
      flex-direction: inherit;
      gap: inherit;
      padding-top: 16px;
      padding-bottom: 4px;
      border-top: 1px solid $theme-border-color;
    }

    .form-field {
      display: flex;
      flex-direction: column;
      gap: 8px;
      min-height: 40px;
    }

    @include media('>=tablet') {
      .form-field {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        >label {
          flex: 0 0 320px;
        }
      }

      .field-content:has(input[type="text"]),
      .field-content:has(input[type="number"]) {
        flex: 1;
      }
    }
  }

  .form-button-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-end;
    padding: 16px;
    background: $theme-title-bg-color;
    border-radius: $theme-border-radius;

    button {
      min-width: 92px;
    }
  }
}

.form-row-40 {
  min-height: 40px;
  align-items: center;
}