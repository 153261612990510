@use 'sass:map';
@use '@angular/material' as mat;
@use 'palette';

@include mat.core();
$material-primary: mat.m2-define-palette(palette.$pointee-palette);
$material-accent: mat.m2-define-palette(palette.$pointee-palette, A200, A100, A400);
$material-warn: mat.m2-define-palette(mat.$m2-red-palette);

$material-theme: mat.m2-define-light-theme((color: (primary: $material-primary,
        accent: $material-accent,
        warn: $material-warn,
      ),
    ));

@include mat.all-component-themes($material-theme);

$config: mat.m2-get-color-config($material-theme);
$foreground: map.get($config, foreground);
$mat-table-border-color: mat.m2-get-color-from-palette($foreground, divider);

.mat-mdc-button-touch-target,
.mat-mdc-focus-indicator,
.mdc-icon-button__ripple {
  display: none;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 9999;
}

.cdk-overlay-dark-backdrop {
  transition: none;
}