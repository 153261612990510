:root {
  --page-header-height: 96px;
  --menu-left-max-width: 256px;
}

/*********** Variables ************/
// layout variables
$menu-max-width: var(--menu-left-max-width);
$page-header-height: var(--page-header-height);

$content-max-width: 1600px;
// variables
$theme-border-radius: 4px;
$input-height: 40px;
$input-line-height: 38px;
$select-min-width: 160px;
$select-max-width: 296px;

// Z-index
$z-index-drawer: 1000 !important;

// Font
$theme-font-size: 14px;
$theme-font-roboto-family: 'Roboto', sans-serif;
$theme-box-shadow: 0px 1px 2px rgba(11, 14, 14, 0.1);
$theme-box-shadow-new: 0px 1px 2px rgba(42, 151, 81, 1);
$theme-box-shadow-active: 0 0 0 1px #1058f5; //rgb(16, 88, 245)
$theme-box-shadow-hover: 0px 1px 2px rgba(11, 14, 14, 0.1), 0px 1px 8px rgba(11, 14, 14, 0.1);
$theme-calendar-box-shadow: 0px 1px 8px 0px rgba(11, 14, 14, 0.15);

// Brand Colours
$color-blue: #1058f5;
$color-blue-light: #4673f5;
$color-blue-lighter: #edf2fe;
$color-blue-dark: #1038aa;
$color-red: #ec1111; // rgb(236, 17, 17)
$color-red-light: #feecec;
$color-red-dark: #bf0d0d;
$color-green: #2a9751;
$color-green-light: #31af5e;
$color-green-lighter: #d9f2e1;
$color-green-dark: #227740;
$color-orange: #ff9634;
$color-orange-2: #feae5a;
$color-orange-light: #fff7db;
$color-orange-2-light: #fff8f0;
$color-gray: #5d5e60;
$color-gray-1: #343a40;
$color-gray-2: #ccc;
$color-gray-3: #666;
$color-gray-4: #f5f5f5;
$color-gray-5: #dfe0e4;
$color-gray-6: #f3f3f3;
$color-gray-7: #808080;
$color-disabled: #b3b3b3;
$color-white: #ffffff;

// Background colors
$theme-bg-color: #f9fafb;
$theme-bg-disabled: #f5f7f7;
$theme-title-bg-color: #f5f7f7;
$theme-tooltip-bg-color: rgba(#3c4049, 0.96);
$theme-bg-white: #ffffff;
$theme-bg-white-transparent: hsla(0, 0%, 100%, 0.75);
$table-hover-bg: #edf2fe52;
// Font Colors
$theme-font-color: $color-gray-1;
$theme-font-gray: #6b7280;
$theme-font-blue: $color-blue-dark;
$theme-font-light: #77787b;
$theme-font-black: #020c27;
$theme-font-header: #3c4049;
$theme-font-inverse: $color-white;
$theme-font-disabled: $color-disabled;
$theme-font-placeholder: $color-disabled;

$action-color: #fff;
$default-color: #d6d6d6;
$disabled-color: $color-gray-2;
$primary-color: $color-blue;
$info-color: $color-blue-light;
$warning-color: #D68100;
$danger-color: $color-red;
$error-color: #ef1f1f;
$success-color: $color-green;
$inverse-color: $theme-font-blue;

$theme-border-color: #ccd4e6;
$theme-border-color-active: $color-blue-light;
$theme-border-color-light: #f5f7f7;
$row-hover-background: rgba($color-gray-2, 0.3);

$graph-color-0: #f5f7f7;
$graph-color-0-hover: darken($graph-color-0, 12%);
$graph-color-1: #fff3cc;
$graph-color-1-hover: darken($graph-color-1, 12%);
$graph-color-2: #ffdfad;
$graph-color-2-hover: darken($graph-color-2, 12%);
$graph-color-3: #febf80;
$graph-color-3-hover: darken($graph-color-3, 12%);

$theme-color: (
  primary: (base: $primary-color,
    hover: lighten($primary-color, 10%),
    active: darken($primary-color, 12%),
    trans: rgba($color-blue-lighter, 0.8),
    bg: $color-blue-lighter,
  ),
  warning: (base: $warning-color,
    hover: lighten($color-orange-light, 5%),
    active: darken($warning-color, 12%),
    trans: transparentize($color-orange-light, 0.5),
    bg: $color-orange-light,
  ),
  default: (base: $default-color,
    hover: lighten($default-color, 0%),
    active: darken($default-color, 12%),
    trans: transparentize($default-color, 0.5),
    bg: $color-gray-6,
  ),
  danger: (base: $color-red,
    hover: lighten($danger-color, 10%),
    active: darken($danger-color, 12%),
    trans: transparentize($danger-color, 0.5),
    bg: $color-red-light,
  ),
  success: (base: $success-color,
    hover: lighten($success-color, 10%),
    active: darken($success-color, 12%),
    trans: transparentize($success-color, 0.5),
  ),
  inverse: (base: $inverse-color,
    hover: lighten($inverse-color, 10%),
    active: darken($inverse-color, 12%),
    trans: transparentize($inverse-color, 0.5),
  ),
  info: (base: $info-color,
    hover: lighten($info-color, 10%),
    active: darken($info-color, 12%),
    trans: transparentize($info-color, 0.5),
    bg: $color-gray-6,
  ),
  disabled: (base: $disabled-color,
    hover: lighten($disabled-color, 10%),
    active: darken($disabled-color, 12%),
    trans: transparentize($disabled-color, 0.5),
  ),
);

// Icon-colors
$icon-colors: (
  primary: (base: $color-blue,
    bg: $color-blue-lighter,
  ),
  success: (base: $color-green-dark,
    bg: $color-green-lighter,
  ),
  warning: (base: #d68100,
    bg: #fff7db,
  ),
);

@function theme-color($name, $variant, $colors: $theme-color) {
  @return map-get(map-get($colors, $name), $variant);
}

@function icon-color($color-name, $color-variant) {
  @return map-get(map-get($icon-colors, $color-name), $color-variant);
}