@use 'sass:color';
@use '../../../general/variables' as *;

// Redefine all classes because of using custom properties
$range-fade-amount: 0.2;
$comparison-color: rgba($color-orange, $range-fade-amount);
$overlap-color: #a8dab5;
$overlap-selected-color: color.adjust($overlap-color, $lightness: -30%);
$highlighted-cell: $primary-color;
$range-color: rgba(237, 242, 254, 0.8);

// focused & active clearing
.cdk-keyboard-focused .mat-calendar-body-active,
.cdk-program-focused .mat-calendar-body-active {
  &>.mat-calendar-body-cell-content {
    color: white;
    background-color: $highlighted-cell;

    &:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      color: $theme-font-black;
      background-color: transparent;
    }
  }
}

// disabled
.mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: $color-disabled;
}

.mat-calendar-body-disabled>.mat-calendar-body-selected {
  opacity: 1;
}

// today
.mat-calendar-body-disabled>.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: $primary-color;
  border-color: transparent;
  box-shadow: none;
}

.calendar-header {
  .calendar-header-label {
    font-weight: 500 !important;
  }
}


mat-calendar {
  border: none;

  .mat-calendar-header,
  mat-calendar-content {
    padding: 0;
  }

  thead th {
    padding: 0 0 8px !important;
  }

  tbody {

    tr,
    td {
      height: 48px;
      max-height: 48px;
    }
  }

  // inner calendar grid
  tr {
    &:hover {
      background: none !important;
    }

    td {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .mat-calendar-content {
    padding: 0;
  }

  .mat-calendar-table-header {
    span {
      font-weight: 500;
      line-height: 16px;
      color: $theme-font-light;
    }
  }

  .mat-calendar-table-header-divider {
    display: none;
  }

  // cell default styling
  .mat-calendar-body-cell {
    .mat-calendar-body-cell-content {
      top: 15%;
      left: 20%;
      width: 60%;
      height: 70%;

      line-height: 24px;
      font-weight: 500;
      border: none;

      &.mat-calendar-body-today {
        color: $primary-color;
        box-shadow: none !important;
      }

      &.mat-calendar-body-selected {
        color: white;
      }
    }

    &.mat-calendar-body-in-range:not(.mat-calendar-body-disabled) {
      .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
        color: $theme-font-header;
      }
    }

    &.inside:not(.mat-calendar-body-in-range) .mat-calendar-body-cell-preview,
    &.mat-calendar-body-in-range:not(.mat-calendar-body-disabled) .mat-calendar-body-cell-preview {
      background-color: $range-color;
    }

    &.inside.from:not(.mat-calendar-body-in-range) .mat-calendar-body-cell-preview,
    &.mat-calendar-body-range-start .mat-calendar-body-cell-preview {
      left: 50%;
      width: 50%;
    }

    &.inside.to:not(.mat-calendar-body-in-range) .mat-calendar-body-cell-preview,
    &.mat-calendar-body-range-end .mat-calendar-body-cell-preview {
      width: 50%;
    }

    &.inside.to:not(.mat-calendar-body-in-range) .mat-calendar-body-cell-preview::after {
      content: '';
      display: block;
      position: absolute;
      left: 40%;
      top: 0;
      width: 32.5px;
      height: 33.04px;
      border-radius: 50%;
      background-color: $range-color;
    }

    &.inside.from.to:not(.mat-calendar-body-in-range) .mat-calendar-body-cell-preview {
      width: 0;

      &::after {
        display: none;
      }
    }

    &.mat-calendar-body-disabled::not(.mat-calendar-body-today) {
      .mat-calendar-body-cell-content {
        color: $color-disabled;
      }

      .mat-calendar-body-cell-preview {
        background-color: transparent;
      }
    }

    &.mat-calendar-body-disabled {
      .mat-calendar-body-cell-content {
        font-weight: 500;
        line-height: 24px;
      }

      cursor: default;
    }
  }

  // cell hover
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: theme-color(primary, base);
    background-color: theme-color(primary, trans);
  }

  // cell in range selection styling
  .mat-calendar-body-cell::before,
  .mat-calendar-body-cell::after {
    display: none;
  }

  .mat-calendar-body-cell-preview {
    top: 15%;
    height: 70%;
  }

  .mat-calendar-controls,
  mat-calendar-controls {
    margin: 0 !important;
  }

  .mat-calendar-body-cell-container {
    border: 1px solid $color-gray-5;
  }

  .mat-calendar-body-label {
    opacity: 0;

    &:hover {
      background-color: transparent;
    }
  }

  .mat-calendar-body-label[colspan='7'] {
    display: none;
  }
}