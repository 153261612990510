@use '../../../general/variables' as *;

.mat-mdc-progress-spinner.mdc-circular-progress {
  position: fixed;
  top: 24px;
  right: 24px;
  width: 32px !important;
  height: 32px !important;
  color: $color-blue;

  &.spinner-button {
    position: relative;
    top: 0;
    right: 0;
    width: 20px !important;
    height: 20px !important;
  }
}
