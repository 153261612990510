@use '../../scss/general/variables' as *;

.pointee-colored-circle {
  display: block;
  background: theme-color(default, base);
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;

  &.small {
    width: 8px;
    height: 8px;
    min-width: 8px;
    min-height: 8px;
  }

  &.primary {
    background: theme-color(primary, base);
  }

  &.warning {
    background: theme-color(warning, base);
  }

  &.danger {
    background: theme-color(danger, base);
  }

  &.success {
    background: theme-color(success, base);
  }

  &.running {
    background-color: $color-green-light;
  }

  &.failing {
    background-color: $color-orange-2;
  }

  &.failed {
    background-color: $error-color;
  }
}
