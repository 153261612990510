@use '../general/variables' as *;

.tooltip-theme-dark {
  font-family: $theme-font-roboto-family;
  background-color: rgba(0, 0, 0, 0.8) !important;
  color: #ffffff !important;

  a,
  p,
  span,
  div,
  i,
  strong,
  small,
  b {
    color: #ffffff !important;
  }

  strong,
  b,
  small,
  b {
    white-space: nowrap;
  }

  &.tooltip-bottom::after {
    border-color: transparent transparent rgba(0, 0, 0, 0.7) transparent !important;
  }

  &.tooltip-left::after {
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.7) !important;
  }

  &.tooltip-top::after {
    border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent !important;
  }

  &.tooltip-right::after {
    border-color: transparent rgba(0, 0, 0, 0.7) transparent transparent !important;
  }
}

.session-tooltip {
  text-align: left;

  &>label {
    min-width: 88px;
    font-weight: bold;
  }

  &>label.param {
    margin-left: 8px;
    font-weight: normal;
  }
}

.tooltip-align-left {
  text-align: left;
}
