@use '../../general/variables' as *;
@use '../../general/mixins' as *;

/**  =====================
      Basic form elements css start
==========================  **/

.form-group {
  position: relative;
  padding-bottom: 16px;
  margin: 0;

  .alert {
    margin: 8px 0 0;
    padding: 0;
  }

  .alert-danger {
    background: none;
    color: $danger-color;
  }

  p {
    margin-bottom: 0;
  }

  label {
    display: block;
    margin-bottom: 8px;
  }

  .suffix {
    padding: 0 16px;
  }
}

.form-control {
  display: flex;
  align-items: center;
  height: auto;
  padding: 0;
  font-family: $theme-font-roboto-family;
  font-size: $theme-font-size;
  color: $theme-font-color;
  line-height: 24px;
  border: 1px solid $theme-border-color;
  border-radius: $theme-border-radius;

  &.is-invalid {
    background: none;
    padding-right: 0;
  }

  &.ng-invalid.ng-touched {
    border-color: $error-color;
  }

  &.ng-invalid.ng-dirty {
    border-color: $error-color;
  }

  &:focus,
  &:active {
    box-shadow: none !important;
    border: 1px solid $primary-color;
  }

  &:hover {
    border: 1px solid $primary-color;
  }

  &.form-control-left {
    text-align: left;
  }

  &.form-control-center {
    text-align: center;
  }

  &.form-control-right {
    text-align: right;
  }

  &.disabled,
  &:disabled {
    cursor: default;
    border: 1px solid $color-gray-5;
    background: $theme-title-bg-color;
    color: $color-disabled;
    pointer-events: none;
  }
}

input[type='text'].form-control,
input[type='password'].form-control {
  text-indent: 12px;
}

textarea.form-control {
  padding: 4px 12px;
}

/*===== Border Inputs start =====*/
$form-border-state: primary, warning, default, danger, success, inverse, info;
$form-border-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;

@each $var in $form-border-state {
  $i: index($form-border-state, $var);

  .form-control-#{$var} {
    border-color: nth($form-border-color, $i);
    color: nth($form-border-color, $i);

    &:focus {
      border-color: nth($form-border-color, $i);
    }
  }

  .input-group-#{$var} {

    .input-group-append,
    .input-group-prepend {
      .input-group-text {
        background-color: nth($form-border-color, $i);
      }
    }

    .form-control {
      border-color: nth($form-border-color, $i);
    }
  }
}

input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type='text'],
input[type='password'] {
  line-height: $input-line-height;
  border: 1px solid $theme-border-color;
  padding: 0;
  height: $input-height;
  background: white;
}



/*===== Form Input-Text start =====*/
$form-txt-state: primary, warning, default, danger, success, inverse, info;
$form-txt-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;

@each $var in $form-txt-state {
  $i: index($form-txt-state, $var);

  .form-txt-#{$var} {
    color: nth($form-txt-color, $i);

    &:focus {
      color: nth($form-txt-color, $i);
    }

    &::-moz-placeholder {
      color: $theme-font-placeholder;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $theme-font-placeholder
    }

    &::-webkit-input-placeholder {
      color: $theme-font-placeholder;
    }
  }
}

/*===== Form Background-inputs start =====*/
$form-bg-state: primary, warning, default, danger, success, inverse, info;
$form-bg-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;

@each $var in $form-bg-state {
  $i: index($form-bg-state, $var);

  .form-bg-#{$var} {
    background-color: nth($form-bg-color, $i);
    border-color: nth($form-bg-color, $i);
    color: #fff;

    &:focus {
      background-color: nth($form-bg-color, $i);
      border-color: nth($form-bg-color, $i);
      color: #fff;
    }

    &::-moz-placeholder {
      color: #fff;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #fff;
    }

    &::-webkit-input-placeholder {
      color: #fff;
    }
  }
}

/*===== Form Validation states start =====*/
$form-valid-state: success, warning, danger;
$form-valid-color: $success-color, $warning-color, $danger-color;

@each $var in $form-valid-state {
  $i: index($form-valid-state, $var);

  .has-#{$var} {
    .form-control-success {
      border-color: nth($form-valid-color, $i);
    }
  }
}

/*===== Form-icon css starts =====*/

.left-icon-control {
  position: relative;

  input {
    padding-left: 50px;
  }

  .form-icon {
    position: absolute;
    top: 50%;
    left: 17px;
    margin-top: -12px;
  }
}

.right-icon-control {
  position: relative;

  input {
    padding-right: 50px;
  }

  .form-icon {
    position: absolute;
    bottom: 8px;
    right: 8px;

    .btn.btn-icon {
      width: 35px;
      height: 35px;
      padding: 0;
    }
  }

  .btn>i {
    margin-right: 0;
  }
}

/*===== Form-icon css ends =====*/
/*===== Basic form elements ends =====*/
/**  =====================
      Form group-add-on css start
==========================  **/

.input-group {
  margin-bottom: 1.25em;
}

.input-group-append,
.input-group-prepend {
  .input-group-text {
    background-color: $primary-color;
    border-color: rgba(0, 0, 0, 0.15);
    color: $color-white;
  }
}

.input-group-button .input-group-addon {
  transition: all ease-in 0.3s;

  &:hover {
    @include buttons-hover(theme-color(primary, hover), theme-color(primary, hover));

    .btn {
      @include buttons-hover(theme-color(primary, hover), theme-color(primary, hover));
    }
  }
}

.input-group-dropdown [class*='btn-'] {
  margin-bottom: 0;
}

/*===== Form group-add-on ends =====*/
/**  =====================
      Switches css start
==========================  **/

.js-dynamic-disable,
.js-dynamic-enable {
  vertical-align: inherit;
}

/*===== Switches css ends =====*/
/**  =====================
      Radio-button css start
==========================  **/

.form-radio {
  position: relative;

  .form-help {
    position: absolute;
    width: 100%;
  }

  label {
    position: relative;
    padding-left: 1.5rem;
    text-align: left;
    color: #333;
    display: block;
    line-height: 1.8;
  }

  input {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
  }
}

.radio {
  .helper {
    position: absolute;
    top: -0.15rem;
    left: -0.25rem;
    cursor: pointer;
    display: block;
    color: #999;

    &::after {
      transform: scale(0);
      background-color: $primary-color;
      border-color: $primary-color;
    }

    &::after,
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 3px;
      margin: 0.25rem;
      width: 1rem;
      height: 1rem;
      -webkit-transition: -webkit-transform 0.28s ease;
      transition: -webkit-transform 0.28s ease;
      transition: transform 0.28s ease, -webkit-transform 0.28s ease;
      border-radius: 50%;
      border: 0.125rem solid $primary-color;
    }
  }

  label:hover .helper {
    color: $primary-color;
  }

  input {
    &:checked~.helper::after {
      transform: scale(0.5);
    }

    &:checked~.helper::before {
      color: $primary-color;
      animation: ripple 0.3s linear forwards;
    }
  }

  &.radio-outline {
    input:checked~.helper::after {
      transform: scale(0.6);
    }

    .helper::after {
      background-color: #fff;
      border: 0.225rem solid $primary-color;
    }
  }

  &.radio-matrial {
    input~.helper::after {
      background-color: #fff;
    }

    input:checked~.helper::after {
      transform: scale(0.5);
      box-shadow: 0 1px 7px -1px rgba(0, 0, 0, 0.72);
    }

    input:checked~.helper::before {
      background-color: $primary-color;
    }
  }

  &.radio-disable {
    label {
      cursor: not-allowed;
    }

    opacity: 0.7;
  }
}

.radio-inline {
  display: inline-block;
  margin-right: 20px;
}

$radio-bg-state: primary, warning, default, danger, success, inverse, info;
$radio-bg-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;

@each $var in $radio-bg-state {
  $i: index($radio-bg-state, $var);

  .radio {
    &.radio-#{$var} {
      .helper::after {
        background-color: nth($radio-bg-color, $i);
        border-color: nth($radio-bg-color, $i);
      }

      .helper::before {
        border-color: nth($radio-bg-color, $i);
      }
    }

    &.radio-outline {
      &.radio-#{$var} {
        .helper::after {
          background-color: #fff;
          border: 0.225rem solid nth($radio-bg-color, $i);
        }

        .helper::before {
          border-color: nth($radio-bg-color, $i);
        }
      }
    }

    &.radio-matrial {
      &.radio-#{$var} {
        input~.helper::after {
          background-color: #fff;
          border-color: #fff;
        }

        input~.helper::before {
          background-color: nth($radio-bg-color, $i);
          border-color: nth($radio-bg-color, $i);
        }
      }
    }
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
  }

  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.05);
  }

  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}

/*===== Radio Button css ends =====*/

/**  =====================
      Validation-forms css start
==========================  **/

.form-group {
  .messages {
    p {
      margin-bottom: 0;
      transition: all ease-in 0.3s;
    }
  }

  .popover-valid {
    position: absolute;
    right: 40px;
    top: 7px;
  }
}

/*===== Validation-forms css end =====*/

/*Form control start*/

.form-material {
  .form-group {
    position: relative;

    &.form-static-label .form-control {
      ~.float-label {
        top: -14px;
        font-size: 11px;
      }
    }
  }

  .form-control {
    display: inline-block;
    width: 100%;
    border: 1px solid $theme-border-color !important;
    padding: 0;
    background-color: transparent;
    box-shadow: none;

    &:focus {
      border-color: transparent;
      outline: none;
      box-shadow: none;

      ~.form-bar {

        &:after,
        &:before {
          width: 50%;
        }
      }
    }

    &:focus,
    &:valid {
      ~.float-label {
        top: -14px;
        font-size: 11px;
      }
    }
  }

  textarea.form-control {
    height: 100%;
    background: $color-gray-4;
    padding: 0.5em;
    font-size: 13px;
  }

  .form-bar {
    position: relative;
    display: block;
    width: 100%;

    &:after,
    &:before {
      content: '';
      height: 2px;
      width: 0;
      bottom: 0;
      position: absolute;
      transition: 0.3s ease all;
      -moz-transition: 0.3s ease all;
      -webkit-transition: 0.3s ease all;
    }

    &:before {
      left: 50%;
    }

    &:after {
      right: 50%;
    }
  }

  .float-label {
    pointer-events: none;
    position: absolute;
    top: 12px;
    left: 0;
    font-weight: 400;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .material-group {
    display: flex;
    margin-bottom: 1.25em;

    .material-addone {
      padding: 0.5rem 0.75rem;
      display: flex;
      align-items: center;

      i,
      span {
        font-size: 20px;
      }
    }

    .form-group {
      flex: 1 1 auto;
      width: 1%;
      margin-bottom: 0;
    }

    &.material-group-lg {
      .material-addone {
        padding: 0.5rem 1rem;

        i,
        span {
          font-size: 1.55rem;
          font-weight: 500;
        }
      }

      .form-control {
        padding: 0.5rem 0;
        font-size: 1.25rem;
        line-height: 24px;

        &:focus,
        &:valid {
          ~.float-label {
            top: -15px;
          }
        }
      }

      .float-label {
        font-size: 1.25rem;
      }
    }

    &.material-group-sm {
      .material-addone {
        padding: 0.25rem 0.5rem;

        i,
        span {
          font-size: 0.775rem;
        }
      }

      .form-control {
        padding: 0.25rem 0;
        font-size: 0.875rem;
        line-height: 1.5;

        &:focus,
        &:valid {
          ~.float-label {
            top: -9px;
            font-size: 11px;
          }
        }
      }

      .float-label {
        font-size: 0.775rem;
      }
    }
  }

  $matrial-state: primary, warning, default, danger, success, inverse, info;
  $matrial-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;

  @each $var in $matrial-state {
    $i: index($matrial-state, $var);

    .form-#{$var} {
      .form-control {
        &:focus {
          ~label {
            color: nth($matrial-color, $i);
          }

          ~.form-bar {

            &:after,
            &:before {
              background: nth($matrial-color, $i);
            }
          }
        }
      }

      &.form-static-label {
        .float-label {
          color: nth($matrial-color, $i);
        }

        .form-control {
          border-bottom-color: nth($matrial-color, $i);
        }
      }
    }

    .material-group-#{$var} {
      .material-addone {

        i,
        span {
          color: nth($matrial-color, $i);
        }
      }
    }
  }
}

select.form-control {

  &,
  &:focus,
  &:hover {
    border-top: none;
    border-right: none;
    border-left: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.form-validation-correct {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
  color: $success-color;
  font-size: 12px;

  .form-control {
    border-color: $success-color !important;
  }
}