@use '../general/variables' as *;

.swal2-container {
  z-index: 10001 !important;
  .swal2-popup {
    font-family: inherit;
    width: 416px;
    padding-bottom: 24px;
  }
}

.swal2-container {
  .swal2-modal {
    .swal2-icon {
      width: 24px;
      height: 24px;
      border: 12px solid transparent;
      margin: 24px auto -18px;

      &.swal2-success {
        color: #2a9751;
        background-color: #d9f2e1;
      }
    }

    .swal2-title {
      font-size: 20px;
      line-height: 32px;
      color: $theme-font-header;
      margin-top: 32px;
      padding: 0 32px;
      font-weight: 500;
    }

    .swal2-html-container {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $theme-font-header;
      margin: 0;
      padding: 16px 32px 0;

      p {
        margin-bottom: 14px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      label {
        width: 100%;
        text-align: left;
        font-weight: 500;
        margin-top: 1rem;
      }
    }

    .swal2-actions {
      margin: 32px auto 0;
    }

    .swal2-validation-message {
      background: none;
      justify-content: start;
      padding: 6px 1em 0;
    }

    .swal2-input {
      margin-top: 8px;
      height: $input-height;
      color: $theme-font-color;
      font-family: $theme-font-roboto-family;
      font-size: 1rem;
      border: 1px solid $theme-border-color;
      border-radius: 4px;

      &:active,
      &:focus,
      &:hover {
        border: 1px solid $theme-border-color-active;
      }

      &:disabled {
        background-color: #eee;
        border: 1px solid #ddd;
        color: $disabled-color;
      }

      &.invalid {
        border: 1px solid $danger-color;
      }
    }

    .swal2-radio {
      flex-direction: column;
      align-items: flex-start;

      .swal2-label {
        font-size: $theme-font-size;
      }

      label {
        display: flex;
        align-items: center;
        margin: 4px 0;
      }

      input {
        width: 18px;
        height: 18px;
        font-size: $theme-font-size;
      }
    }

    .swal2-close {
      border: none;
      font-family: $theme-font-roboto-family;
      color: $theme-font-color;
      font-size: 32px;

      &:hover {
        color: $theme-font-header;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .swal2-actions {
      .swal2-styled {
        min-width: 170px;
        margin: 0 6px;
        padding: 12px 16px;
        box-shadow: none;
        font-weight: 500;
      }

      .swal2-confirm,
      .swal2-cancel {
        border: 0;
        border-right-color: currentcolor;
        border-left-color: currentcolor;
        border-radius: 4px;
        background: initial;
        background-color: initial;
        color: $color-white;
        font-size: $theme-font-size;
      }

      .swal2-cancel {
        background-color: white;
        color: $theme-font-header;
        border: 2px solid #e6e6e6;
      }

      .swal2-cancel,
      .swal2-confirm {
        &.primary {
          background-color: $primary-color;
          color: white;
          border-color: $primary-color;
        }

        &.success {
          background-color: $primary-color;
        }

        &.danger {
          background-color: $color-red-dark;
        }

        &.default {
          background-color: white;
          color: $theme-font-header;
        }

        &.link {
          color: $color-blue;
          background-color: transparent;

          &:hover {
            background-image: none;
          }
        }
      }
    }
  }
}
