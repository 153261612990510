:root {
  --mdc-chip-label-text-line-height: 24px;
  --mdc-chip-elevated-container-color: #f5f7f7 !important;
}

.mat-mdc-standard-chip .mdc-evolution-chip__icon--trailing {
  height: 10px;
  width: 10px !important;
  font-size: 10px !important;
}

.mat-mdc-chip-set .mdc-evolution-chip {
  margin: 0 0 0 4px !important;
  border: 1px solid #ccd4e6;
  background-color: #f5f7f7 !important;
}