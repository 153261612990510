@use 'variables' as *;

/*====== Padding , Margin, flex gap ======*/

$i: 0;

@while $i<=64 {
  .p {
    &-#{$i} {
      padding: #{$i}px;
    }

    &-t-#{$i} {
      padding-top: #{$i}px;
    }

    &-b-#{$i} {
      padding-bottom: #{$i}px;
    }

    &-l-#{$i} {
      padding-left: #{$i}px;
    }

    &-r-#{$i} {
      padding-right: #{$i}px;
    }

    &-x-#{$i} {
      padding-left: #{$i}px;
      padding-right: #{$i}px;
    }

    &-y-#{$i} {
      padding-top: #{$i}px;
      padding-bottom: #{$i}px;
    }
  }

  .m {
    &-#{$i} {
      margin: #{$i}px;
    }

    &-t-#{$i} {
      margin-top: #{$i}px;
    }

    &-b-#{$i} {
      margin-bottom: #{$i}px;
    }

    &-l-#{$i} {
      margin-left: #{$i}px;
    }

    &-r-#{$i} {
      margin-right: #{$i}px;
    }

    &-x-#{$i} {
      margin-left: #{$i}px;
      margin-right: #{$i}px;
    }

    &-y-#{$i} {
      margin-top: #{$i}px;
      margin-bottom: #{$i}px;
    }
  }

  // ************ Flex Gaps ************
  .flex-gap-#{$i} {
    gap: #{$i}px;
  }

  $i: $i + 4;
}

.m-l-auto {
  margin-left: auto;
}

.m-r-auto {
  margin-right: auto;
}

.m-t-auto {
  margin-top: auto;
}

.m-b-auto {
  margin-bottom: auto;
}

/*====== Font-style ======*/
.f-s {
  &-normal {
    font-style: normal;
  }

  &-italic {
    font-style: italic;
  }

  &-oblique {
    font-style: oblique;
  }

  &-initial {
    font-style: initial;
  }

  &-inherit {
    font-style: inherit;
  }
}

/*====== Position ======*/
.pos {
  &-static {
    position: static;
  }

  &-absolute {
    position: absolute;
  }

  &-fixed {
    position: fixed;
  }

  &-relative {
    position: relative;
  }

  &-initial {
    position: initial;
  }

  &-inherit {
    position: inherit;
  }
}

/*====== Overflow ======*/

$i: hidden, visible, auto;

@each $val in $i {
  .o-#{$val} {
    overflow: $val;
  }
}

/*====== Text transform and decoration ======*/

.text {
  &-capitalize {
    text-transform: capitalize;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-lowercase {
    text-transform: lowercase;
  }

  &-overline {
    text-decoration: overline;
  }

  &-line-through {
    text-decoration: line-through;
  }

  &-underline {
    text-decoration: underline;
  }

  &-none {
    text-transform: none;
    text-decoration: none;
  }

  &-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-break-all {
    word-break: break-all;
  }
}

/*====== Colors ======*/

.color {
  &-primary {
    color: $primary-color !important;
  }

  &-success {
    color: $success-color !important;
  }

  &-danger {
    color: $danger-color !important;
  }

  &-error {
    color: $error-color !important;
  }

  &-warning {
    color: $warning-color !important;
  }

  &-warning-icon {
    color: icon-color(warning, base) !important;
  }

  &-disabled {
    color: $color-disabled !important;
  }

  &-black {
    color: $theme-font-black !important;
  }

  &-gray {
    color: $theme-font-gray !important;
  }

  &-red {
    color: $color-red !important;
  }

  &-red-dark {
    color: $color-red-dark !important;
  }

  &-green {
    color: $color-green !important;
  }

  &-green-dark {
    color: $color-green-dark !important;
  }
}

/*====== Cursor ======*/

.cursor {
  &-pointer {
    cursor: pointer;
  }
}
