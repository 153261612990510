@use '../general/variables' as *;

.timelines-chart {
  font-size: 12px;

  svg {
    width: auto !important;
  }

  .axises {

    line,
    path {
      stroke: white !important;
    }

    .x-axis {
      font: 12px $theme-font-roboto-family !important;
    }

    .x-grid line {
      stroke: white;
      stroke-width: 1;
    }

    .y-axis,
    .grp-axis {

      line,
      path {
        stroke: white;
      }

      text {
        fill: $theme-font-color;
      }
    }
  }

  // line
  .series-group {
    fill: #f5f7f7 !important;
    stroke: white !important;
    fill-opacity: 1 !important;
    stroke-opacity: 1 !important;

    &:hover {
      fill: theme-color(primary, base) !important;
      fill-opacity: 0.04 !important;
    }
  }

  // segment = one item in line
  .series-segment {
    border-radius: 4px;
    // prednastavene
    // fill-opacity: 0.8;
    // on-hover: fill-opacity: 1;
  }

  .series-group,
  .series-segment {
    cursor: crosshair;
  }

  line.x-axis-date-marker {
    stroke-width: 1;
    stroke: white !important;
    fill: white !important;
  }

  .reset-zoom-btn {
    display: none;
  }
}

.brusher {
  margin-bottom: 16px;

  .grid-background {
    fill: lightgrey;
  }

  .tick text {
    text-anchor: middle;
  }

  .grid {

    line,
    path {
      stroke: #fff;
    }
  }
}

.chart-zoom-selection,
.brusher .brush .selection {
  stroke: blue;
  stroke-opacity: 0.6;
  fill: blue;
  fill-opacity: 0.3;
  shape-rendering: crispEdges;
}

.chart-tooltip {
  color: white !important;
  padding: 6px 12px;
  border-radius: 3px;
  z-index: 99;

  b,
  strong,
  p {
    font-family: $theme-font-roboto-family;
    font-size: $theme-font-size !important;
    color: white;
    padding: 0;
    margin: 0;
  }

  &.segment-tooltip {
    background: $theme-tooltip-bg-color;
    color: white;
    text-align: left;
  }

  &.group-tooltip {
    background: white !important;
    color: $theme-font-color !important;
    font-weight: bold;
    padding: 4px;
  }

  &.line-tooltip {
    display: none;
  }

  // row-bg
  .series-group {
    fill: #f5f7f7 !important;
    stroke: white;
  }
}