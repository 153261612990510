@use '../general/variables' as *;

.tab-button-group {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: 8px;
}

.tab-button {
  display: flex;
  padding: 4px 12px;
  font-weight: 500;
  line-height: 24px;
  color: $theme-font-gray;
  background: $theme-title-bg-color;
  border-radius: $theme-border-radius;
  cursor: pointer;

  &:hover {
    background: $color-blue-lighter;
    color: $primary-color;
  }

  &.active {
    background: $color-blue-lighter;
    color: $primary-color;
  }

  &.disabled {
    background: transparent;
    color: $theme-font-disabled;
    cursor: default;
  }
}
