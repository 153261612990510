@use '../../../general/variables' as *;

.mat-mdc-tab {
  --mdc-secondary-navigation-tab-container-height: 40px;
  --mat-tab-header-label-text-weight: 500;
  --mat-tab-header-inactive-hover-label-text-color: $theme-font-header;
  // --mat-tab-header-inactive-ripple-color: $theme-border-color; - on hover background color

  min-width: 72px !important;
  padding: 0 12px !important;
  border-radius: $theme-border-radius;

  .mat-mdc-tab .mdc-tab__ripple::before {
    border-radius: 4px;
  }
}