.chart-js {
  canvas {
    display: flex;
  }
}

.chart-container {
  display: block;
  width: 100%;

  mat-button-toggle-group {
    margin-bottom: 32px;
  }
}

.common-chart {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;

  canvas {
    max-height: 100%;
    max-width: 100%;
  }
}

.graph-legend {
  display: flex;
  flex-wrap: wrap;
  column-gap: 32px;
  row-gap: 8px;

  max-width: 100%;
  margin: 0 0 20px 0;

  div.legend-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .legend-text {
      max-width: 330px;
      font-weight: normal;
      line-height: 24px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}