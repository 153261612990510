@use 'include-media' as *;

@include media('<tablet') {
  .display-tablet-and-higher {
    display: none !important;
  }

  .mobile-flex-expand {
    flex-basis: 100%;
  }
}

@include media('>=tablet') {
  .mobile-only {
    display: none;
  }

  .hide-tablet-and-higher {
    display: none !important;
  }
}