@use '../general/variables' as *;

.rounded-pill {
  border-radius: 8px;
  padding: 2px 12px;

  &.danger {
    color: theme-color(danger, active);
    background: theme-color(danger, bg);
  }

  &.warning {
    color: theme-color(warning, active);
    background: theme-color(warning, bg);
  }

  &.success {
    color: theme-color(success, active);
    background: theme-color(success, bg);
  }

  &.default {
    color: $theme-font-black;
    background: theme-color(info, bg);
  }
}

.pill-state-button {
  cursor: pointer;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  align-items: center;
  background: $color-gray-6;
  height: 24px;
  line-height: 24px;
  font-weight: 500;
  border-radius: 12px;
  overflow: hidden;

  &.disabled {
    cursor: default;
  }

  span {
    display: flex;
    align-items: center;
    margin: 0 12px;

    svg-icon {
      margin-right: 8px;
    }
  }

  svg-icon {
    font-size: $theme-font-size;
  }

  button {
    display: flex;
    opacity: 0;
    visibility: hidden;
    width: 0;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: none;
    background: none;
    padding-left: 0;
    padding-right: 0;
    transition: visibility 0s linear 0s, opacity 0s, width 300ms;

    svg-icon {
      font-size: 11px !important;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }
  }

  &.succeed {
    color: $color-green-dark;
    background: $color-green-lighter;

    button {
      border-left: 1px solid #8dd8a5;
    }

    svg-icon {
      color: $color-green-dark;
    }

    button.icon-btn {

      &:hover,
      &:active,
      &:focus {
        svg-icon {
          color: white;
        }

        background: $color-green;
      }
    }
  }

  &.error {
    color: $color-red-dark;
    background: $color-red-light;

    button {
      border-left: 1px solid #fccdcd;
    }

    svg-icon {
      color: $color-red-dark;
    }

    button.icon-btn {

      &:hover,
      &:active,
      &:focus {
        svg-icon {
          color: white;
        }

        background: $color-red;
      }
    }
  }
}