@use '../general/variables' as *;

.badge {
  position: absolute;
  top: 0;
  right: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  min-width: 16px;
  padding: 0 2px;
  background-color: $error-color;
  color: white;
  border-radius: 10px;
  font-family: $theme-font-roboto-family;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  z-index: 1;

  &.large {
    position: relative;
    right: 0;
    height: 20px;
    min-width: 20px;
    padding: 0 2px;
  }
}