@use '../general/variables' as *;

div.data-section {
  display: flex;
  flex-flow: column;

  h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    padding: 0 0 16px;
    margin: 0;
  }

  .data-panel {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    background: white;
    border-radius: $theme-border-radius;
    margin-bottom: 40px;

    .row {
      min-width: 100%;
    }

    &.small-gap {
      margin-bottom: 1rem;
    }
  }
}
