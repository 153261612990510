// Import theming functions
@use '@angular/material' as mat;
@use '../../../general/variables' as *;
@include mat.radio-density(-1);

:root {
  --mdc-radio-state-layer-size: 24px;
  --mdc-theme-text-primary-on-background: $color-light-blue;
}

@mixin pointee-radio-color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat-get-color-config($config-or-theme);
  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);

  // Define any styles affected by the theme.
  .pointee-radio-button {
    // Use mat-color to extract individual colors from a palette.
    background-color: mat-color($config);
    border-color: mat-color($config, A400);
  }
}

@mixin pointee-radio-button-typography($config-or-theme) {
  // Extract the typography configuration in case a theme has been passed.
  $config: mat-get-typography-config($config-or-theme);

  .pointee-radio-button {
    font: {
      family: mat-font-family($config, body-1);
      size: mat-font-size($config, body-1);
      weight: mat-font-weight($config, body-1);
    }
  }
}

@mixin pointee-radio-button-theme($theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat-get-color-config($theme);
  $typography: mat-get-typography-config($theme);

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color !=null {
    @include pointee-radio-color($color);
  }

  @if $typography !=null {
    @include pointee-radio-button-typography($typography);
  }
}

.mat-mdc-radio-button {
  .mdc-radio__outer-circle {
    background: white !important;
  }

  .mdc-radio--disabled+label {
    cursor: default !important;
  }
}



.pointee-radio-button {
  label {
    margin-bottom: 0;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .mdc-radio {
    display: flex;
    align-items: center;
    padding: 0 !important;

    .mat-mdc-radio-touch-target {
      height: 18px;
      width: 18px;
    }

    .mdc-form-field {
      margin: 0 !important;
      width: 100%;

      label {
        padding-left: 12px !important;
      }
    }

    .mdc-radio__background {
      width: 18px;
      height: 18px;

      .mdc-radio__inner-circle {
        width: 18px;
        height: 18px;
        border-width: 0;
        background: $color-blue;
      }
    }
  }
}

mat-radio-group {
  display: flex;
  column-gap: 16px;
  row-gap: 4px;
  min-height: 40px;

  label {
    margin-bottom: 0 !important;
    cursor: pointer;
  }
}