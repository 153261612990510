@use 'variables' as *;
@use 'include-media' as *;

/**  =====================
      HTML elements start
==========================  **/
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  line-height: 23px;
  font-family: $theme-font-roboto-family;

  &:focus {
    outline: none;
  }
}

html {
  .theme-font-family {
    font-family: $theme-font-roboto-family;
  }
}

body {
  font-size: $theme-font-size;
  color: $theme-font-color;
  background-color: $theme-bg-color;
  background-attachment: fixed;
  cursor: default;
  overflow-x: hidden;
}

pre {
  font-family: $theme-font-roboto-family;
}

ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;

  &.bullets li {
    padding-left: 24px;
    text-indent: -24px;

    &::before {
      content: '';
      display: inline-block;
      -webkit-border-radius: 4px;
      border-radius: 4px;
      height: 8px;
      width: 8px;
      background-color: $color-gray-5;
      margin: 2px 12px 2px 0;
    }
  }

  &.odd-background li {
    &:nth-child(odd) {
      background-color: $theme-title-bg-color;
    }
  }
}

h1,
h2,
h3,
h4 {
  margin: 0;
  color: $theme-font-header;
  line-height: 32px;
  overflow-wrap: break-word;
}

h1,
h2 {
  font-weight: 700;

  @include media('>=tablet') {
    font-weight: 700;
  }
}

h1 {
  font-size: 34px;
}

h2 {
  font-size: 20px;

  @include media('>=tablet') {
    font-size: 24px;
  }
}

h3 {
  font-size: 20px;
  font-weight: 500;
}

h4 {
  font-size: 16px;
  font-weight: 500;
}

h5 {
  font-size: 12px;
  font-weight: 500;
  color: $theme-font-gray;
  line-height: 16px;
  margin-bottom: 0;
}

b,
strong {
  font-weight: 500;

  .stronger {
    font-weight: 700;
  }
}

p {
  margin-bottom: 16px;
}

a {
  color: $primary-color;
  text-decoration: none;
  font-weight: 500;

  &:focus,
  &:hover {
    text-decoration: none;
    color: $inverse-color;
    outline: none;
  }

  &.disabled,
  &.disabled .menu-icon {
    pointer-events: none;
    background-color: white !important;
    box-shadow: none !important;
    color: $theme-font-gray !important;
  }

  &.link-no-style {
    color: inherit;
    /* blue colors for links too */
    text-decoration: inherit;
    /* no underline */
    font-size: inherit;
    font-weight: 400;

    &:focus,
    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }
}

small {
  font-size: 12px;
  line-height: 16px;
  color: $theme-font-gray;
}

hr {
  margin: 0 0 32px 0;
  border-top: 1px solid $theme-border-color;
  border-left: 1px solid $theme-border-color;
  opacity: 1;
}

table {
  width: 100%;
}

input::placeholder {
  text-align: left;
  color: $theme-font-placeholder !important;
  font-weight: normal;
}

input::-moz-placeholder {
  color: $theme-font-placeholder;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: $theme-font-placeholder;
}

input::-webkit-input-placeholder {
  color: $theme-font-placeholder;
}

/**====== HTML elements end ======**/

.sticky-to-top {
  position: fixed;
  left: $menu-max-width;
  right: 0;
  top: 0;
  z-index: 99;
  backdrop-filter: blur(8px);
}

.no-data-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - $page-header-height);

  .no-data-item {
    position: relative;
    display: block;
    text-align: center;
    margin-top: calc(-1 * $page-header-height);

    &.narrow {
      max-width: 400px;
    }

    p {
      font-size: 16px;
    }

    svg-icon {
      font-size: 56px !important;
      color: $theme-font-light;
    }
  }
}

.text-link {
  padding: 0;
  margin: 0;
  font-weight: 500;
  color: $color-blue;
  font-style: normal;
  text-decoration: none;
  cursor: pointer;
  text-indent: 0 !important;

  svg-icon {
    margin-left: 8px;
    font-size: 11px;
  }

  &.icon {
    svg-icon {
      line-height: 1;
    }

    display: flex;
    align-items: center;
  }

  &.no-bullet {
    &::before {
      display: none !important;
    }
  }
}

.no-margin {
  margin: 0 !important;
}

.no-margin-padding {
  margin: 0;
  padding: 0;
}

.section,
.section-flex {
  margin-bottom: 24px;

  @include media('>=tablet') {
    margin-bottom: 40px;

    &.smaller {
      margin-bottom: 24px;
    }

    &.bigger {
      margin-bottom: 64px;
    }
  }

  .panel.gap:last-child {
    margin-bottom: 0;
  }
}

.panel {
  &.panel-bg-gray {
    background-color: $theme-title-bg-color;
  }
}

.bottom-divider {
  border-bottom: 1px solid $theme-border-color;
}

.prevent-select {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}