@use '../../../general/variables' as *;

:root {
  --mat-select-trigger-text-line-height: 40px;
}

.mat-mdc-select-panel {
  box-shadow: none;
}

mat-select {
  display: flex !important;
  flex: 1;
  background: white;
  border: 1px solid $theme-border-color;
  border-radius: $theme-border-radius;
  margin: 0;
  max-height: 400px;
  letter-spacing: normal;

  .mat-mdc-select-trigger {
    line-height: 38px;
    min-height: 38px;
  }

  .mat-mdc-select-arrow-wrapper {
    padding: 0 12px 0 0;

    svg {
      display: none;
    }
  }

  .mat-mdc-select-arrow {
    margin-top: -2px;
    width: 8px;
    height: 8px;
    border-top: 0;
    border-right: 2px solid $theme-font-gray;
    border-bottom: 2px solid $theme-font-gray;
    border-left: 0;
    transform: rotate(45deg) !important;
  }

  .mat-mdc-select-value {
    padding: 0 12px;
    color: $theme-font-header;
    font-weight: 400;

    .mat-mdc-select-value-text {
      display: flex;
      align-items: center;
    }
  }

  &:hover:not(.mat-mdc-select-disabled):not(.mat-mdc-select-invalid),
  &:active:not(.mat-mdc-select-disabled):not(.mat-mdc-select-invalid),
  &:focus:not(.mat-mdc-select-disabled):not(.mat-mdc-select-invalid) {
    border: 1px solid $primary-color;

    .mat-mdc-select-arrow {
      border-right: 2px solid $primary-color;
      border-bottom: 2px solid $primary-color;
    }
  }

  &.mat-mdc-select-invalid {
    border: 1px solid $danger-color;
  }

  mat-select-trigger {
    display: flex;
    gap: 12px;
    max-width: 100%;

    svg-icon {
      color: $primary-color;
      min-width: 16px;
    }

    i {
      display: none;
      margin: 0 12px 0 0;
    }
  }
}

mat-select.unlimited-height {
  min-height: unset;
}

mat-select.mat-mdc-select-disabled {
  cursor: default;
  border: 1px solid $color-gray-5;
  background: $theme-title-bg-color;
  color: $color-disabled;

  mat-select-trigger {
    svg-icon {
      color: $color-disabled;
    }
  }

  .mat-mdc-select-arrow {
    border-right: 2px solid $color-disabled;
    border-bottom: 2px solid $color-disabled;
  }
}

ngx-mat-select-search {
  .mat-select-search-inner-row {
    display: flex;
    color: $theme-font-gray;
  }

  .mat-select-search-custom-header-content {
    order: 1;
    display: inline-flex;
    padding-left: 12px;
  }

  .mat-select-search-input {
    order: 2;
    padding: 12px !important;
  }

  .mat-select-search-clear {
    padding: 8px !important;
    width: auto !important;
    height: auto !important;
  }
}

.select-content-width {
  min-width: $select-min-width;
  max-width: max-content;
}

.pointee-mat-select {
  min-width: $select-min-width;
  max-width: $select-max-width;
  width: max-content !important;

  &.expandable {
    max-width: 100% !important;
  }
}

.pointee-mat-select-panel {
  width: 100%;
  min-width: fit-content;
}

.pointee-mat-select-panel-bigger {
  max-height: 500px !important;
}

// mat-select-overlay - default class for this
.pointee-mat-select-overlay {
  margin: 8px 0;
  box-shadow: 0px 1px 8px rgba(11, 14, 14, 0.15);
  max-height: 100%;
  min-width: fit-content;

  .mat-mdc-option {
    font-family: $theme-font-roboto-family;
    padding: 9px 12px;
    min-height: unset;
    line-height: 22px;

    .mat-mdc-button-persistent-ripple,
    .mat-mdc-button-touch-target {
      display: none;
    }

    &.contains-mat-select-search {
      height: 40px;
      margin-top: 0 !important;
    }

    &.custom-bottom-border {
      box-shadow: $theme-box-shadow;
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 24px;
    }

    &.mdc-list-item--selected span {
      color: $theme-font-black;
      font-weight: 500;
    }
  }
}

.pointee-html .mat-mdc-optgroup {
  &:not(:first-of-type) .mat-mdc-optgroup-label {
    margin-top: 16px;
  }

  .mat-mdc-optgroup-label {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: $theme-font-gray;
  }

  .mat-mdc-option:not(.mat-mdc-option-multiple) {
    padding-left: 16px;
  }
}