@use '../general/variables' as *;
@use 'include-media' as *;

/**
==========================
Breadcrumb css
==========================
**/
$item-color-hover: darken($primary-color, 5%);

.breadcrumb-container {
  display: flex;
  align-items: center;
  overflow: hidden;

  * {
    font-family: $theme-font-roboto-family;
    line-height: 24px;
  }

  a.breadcrumb-item {
    font-weight: 400;

    &:hover {
      color: $item-color-hover;

      svg-icon {
        color: $item-color-hover;
      }
    }
  }

  .breadcrumb-item {
    display: inline-flex;
    color: $theme-font-header;

    .item-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:last-child {
      font-weight: 500;
    }
  }

  svg-icon.arrow-icon {
    display: flex;
    color: $theme-font-gray;
    margin: 0 8px;
    font-size: 16px;
    min-width: 16px;
  }
}

/**====== Breadcrumb css end ======**/