@use 'include-media' as *;

.label-content-1-3-grid-container {
  display: grid;
  grid-row-gap: 12px;

  .label {
    display: flex;

    p {
      margin-bottom: 0;
    }
  }

  @include media('<tablet') {
    .label {
      gap: 8px
    }
  }

  @include media('>=tablet') {
    grid-template-columns: repeat(12, [col] 1fr);
    grid-row-gap: 0;
    grid-column-gap: 12px;

    .label {
      grid-column: col 1 / span 3;
      grid-row: row;
      display: flex;
      flex-direction: column;

      &.single-col {
        grid-column: col 1 / span 12;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .data-content {
      grid-column: col 4 / span 9;
      grid-row: row;
    }
  }

  &.single-col {
    display: flex;
    flex: 1;
  }
}