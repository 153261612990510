@use 'mixins' as *;
@use 'variables' as *;
@use 'include-media' as *;
@use '../overrides/bootstrap/button' as *;

/**  =====================
      Main-body css start
==========================  **/
.main {
  position: relative;
  display: block;
  width: 100vw;

  .main-container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100%;
    overflow: hidden;

    .main-body {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin: 0 auto;
      overflow-x: auto;

      .page-wrapper {
        position: relative;
        display: block;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        overflow: auto;

        .page-container {
          position: relative;
          max-width: $content-max-width;
          height: 100%;
          margin: 0 auto;
          padding: 0 8px;

          &.full-width {
            max-width: 100%;
            padding: 0 !important;
          }

          @include media('>=tablet') {
            padding: 0 16px;
          }

          @include media('>=desktop') {
            padding: 0 32px;
          }

          @include media('>=1920px') {
            padding: 0;
          }

          .page-header-container {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            flex-wrap: wrap;
            min-height: $page-header-height;
            padding: 32px 0 24px;
            gap: 16px 8px;

            .page-header {
              display: flex;
              align-items: flex-end;
              flex: 1;
              min-height: 40px;
              gap: 16px;
            }

            .header-icon-btn {
              @extend .icon-btn;
              height: 40px;
              border-radius: 20px;

              &:hover:not(:disabled) {
                svg-icon {
                  color: $theme-font-black;
                }
              }

              &.selected {
                svg-icon {
                  color: $primary-color;
                }

                background: theme-color(primary, trans);
              }
            }
          }
        }
      }
    }
  }
}

app-page-header-container {
  h2 {
    max-width: 100%;
  }
}

.auth-form {
  padding-bottom: 8px;
}

.auth-form-href {
  margin-top: 16px;
  text-align: center;
}

.pointee-html {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;

  .deviated {
    font-weight: 500;
    color: $color-red !important;
  }

  .defined {
    font-weight: 500;
    color: $color-orange;
  }

  .scrollTable {
    overflow-y: auto !important;
    /* Trigger vertical scroll    */
    overflow-x: auto !important;
    /* Hide the horizontal scroll */
  }

  .accordion {
    .accordion-item {
      overflow: visible;

      .accordion-header {
        padding: 0px;
      }
    }

    .card {
      padding: 0;
      box-shadow: none;
      border: none;
    }
  }

  .clickable {
    cursor: pointer;
  }

  svg-icon {
    display: inline-flex;
    align-self: center;
  }

  svg {
    display: inline-flex;
    height: 100%;
    vertical-align: baseline;
    overflow: visible;
  }

  input[type='number'] {
    width: 100px;

    &.three-digits {
      width: 75px;
    }

    &.w-100 {
      width: 100%;
    }
  }

  .pointee-dropdown {
    position: relative;
    width: 100%;

    button.dropdown-toggle {
      border-radius: 8px;
      background: lighten($primary-color, 10%) !important;
      width: 100% !important;
      padding: 8px 14px;

      span {
        display: inline-flex;
      }

      .search-icon {
        width: 24px;
        margin-right: 24px;
      }

      .search-placeholder {
        width: calc(100% - 8px);
      }
    }

    &.light {
      .btn {
        background: transparent !important;
        border-color: $theme-border-color-active;
      }
    }
  }

  ngx-file-drop {
    .ngx-file-drop__drop-zone {
      height: 100%;
      margin: auto;
      border: 1px dashed $theme-font-light;
      border-radius: 0;

      .ngx-file-drop__content {
        height: 100%;
      }
    }
  }

  process-map-details {
    shared-context-dialog-box {
      top: 16px;

      @include media('>=tablet') {
        top: 32px;
      }

      margin-right: 16px;
    }

    .app-dialog-body {
      .btn-link {
        color: $theme-font-color;
      }
    }

    .app-dialog-footer {
      .btn-link {
        height: 32px;
        margin-top: 12px;
        justify-content: flex-start;

        svg-icon {
          margin-left: 0;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .card {
      margin-bottom: 8px !important;
    }

    .card-body {
      padding: 0 !important;
    }
  }

  shared-routing-tabs {
    position: relative;
    max-width: fit-content;
    overflow: hidden;
  }
}

svg {
  overflow: visible;
  vertical-align: inherit;
}

.modal-size-sm {
  max-width: 416px;
}

.modal-size-md {
  max-width: 500px;
}

.modal-size-lg {
  max-width: 640px;
}

.modal-size-xl {
  max-width: 740px;
}

.modal-size-xxl {
  max-width: 960px;
}
