@use '../../general/variables' as *;
@use '../../general/mixins' as *;

/**  =====================
      Theme-Buttons css start
==========================  **/
button {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.btn-group-flex {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: 16px;

  .btn,
  .mat-mdc-select {
    flex-grow: 1;
    display: inline-flex !important;
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 8px 12px;
  font-family: $theme-font-roboto-family;
  font-size: $theme-font-size;
  font-weight: 500;
  // line-height: 24px;
  max-height: 40px;
  height: 100%;
  border: 0;
  border-radius: 4px;
  outline: none !important;
  user-select: none;
  vertical-align: unset;
  white-space: nowrap;
  transition: none;

  &.danger {
    color: theme-color(danger, base);

    &:hover {
      color: theme-color(danger, active);
    }
  }

  span {
    font-family: $theme-font-roboto-family;
  }

  &.arrow {
    justify-content: flex-end;

    svg-icon {
      margin-right: 0;
      margin-right: 0.6rem;
    }
  }

  &-cancel {
    color: $theme-font-gray;
  }

  &-round {
    border-radius: 2rem;
  }

  &-icon {
    width: 40px;
    line-height: 30px;
    height: 40px;
    text-align: center;
    font-weight: 400;

    &.small {
      width: 32px;
      height: 32px;
      line-height: 32px;
    }

    &.selected {
      background: $color-blue-lighter;
      color: $color-blue;
    }

    svg {
      min-width: 16px;
      min-height: 16px;
    }
  }

  &-icon-only {
    padding: 8px 4px;
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;

    &:hover,
    &:active {
      color: darken($primary-color, 5%);
    }
  }

  &-ghost {
    height: 32px;

    &:hover,
    &:active,
    &:focus {
      color: $primary-color !important;
      outline: none !important;
      box-shadow: none !important;
    }

    .active {
      color: $primary-color !important;
      background-color: $color-blue-lighter;
    }
  }

  &.fit-content {
    width: fit-content;
  }

  &-block {
    width: 100%;
  }

  &-link {
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
    padding: 4px 8px;
    color: theme-color(primary, base);
    text-decoration: none;
    outline: none;
    border: none;

    svg-icon {
      &.left {
        margin-right: 0;
      }
    }

    &.collapsed {
      svg-icon {
        transform: rotate(180deg);
      }
    }

    &.inline {
      display: inline-flex;
      padding: 0;
    }

    &.normal {
      font-weight: 400;
    }

    &.bold {
      font-weight: 500;
    }



    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      outline: 0;
      box-shadow: none;
      color: theme-color(primary, base);
    }

    &.danger {
      color: theme-color(danger, base);

      &:hover,
      &:active,
      &:focus {
        color: theme-color(danger, base);
      }
    }
  }

  &-primitive {
    border: none;
    box-shadow: #e6e6e6 0px 0px 0px 2px inset;

    &.opaque {
      background: $theme-bg-white;
    }

    &:hover,
    &:active {
      box-shadow: none;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  &-ghost-primitive {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: auto;
    border: none;
    box-shadow: none;
    background: none;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      color: inherit;
    }

    svg-icon {
      display: flex !important;
    }
  }

  &.disabled,
  &:disabled {
    cursor: default;
    opacity: 1;
    box-shadow: none;
    background: #c6cddc;
    color: white;
    border: 0;
    pointer-events: none;

    &:hover,
    &:active {
      color: white;
      border: 0;
    }

    .btn-icon {

      &:hover,
      &:active {
        background: transparent;
        color: $color-disabled;
      }
    }

    &.btn-link {
      background: none;
      color: $theme-font-disabled;
    }
  }

  &.btn-md {
    max-height: 40px;
  }
}

.btn {

  /**====== Basic-Buttons css Start ======**/
  &-primary {
    @include buttons(theme-color(primary, base), theme-color(primary, base));

    &:hover {
      @include buttons-hover(theme-color(primary, hover), theme-color(primary, hover));
    }

    &:active {
      @include buttons-active(theme-color(primary, active), theme-color(primary, active));
    }

    &:focus {
      @include buttons-focus(theme-color(primary, hover));
    }

    &.disabled {
      @include button-trans(#c4ced4, $color-disabled);
    }
  }

  &-warning {
    @include buttons(theme-color(warning, base), theme-color(warning, base));

    &:hover {
      @include buttons-hover(theme-color(warning, hover), theme-color(warning, hover));
      color: #fff;
    }

    &:active {
      @include buttons-active(theme-color(warning, active), theme-color(warning, active));
    }

    &:focus {
      @include buttons-focus(theme-color(warning, hover));
    }

    &.disabled {
      @include button-trans(theme-color(warning, trans), theme-color(warning, trans));
    }
  }

  &-danger {
    @include buttons(theme-color(danger, base), theme-color(danger, base));

    &:hover {
      @include buttons-hover(theme-color(danger, hover), theme-color(danger, hover));
    }

    &:active {
      @include buttons-active(theme-color(danger, active), theme-color(danger, active));
    }

    &:focus {
      @include buttons-focus(theme-color(danger, hover));
    }

    &.disabled {
      @include button-trans(theme-color(danger, trans), theme-color(danger, trans));
    }
  }

  &-success {
    @include buttons(theme-color(success, base), theme-color(success, base));

    &:hover {
      @include buttons-hover(theme-color(success, hover), theme-color(success, hover));
    }

    &:active {
      @include buttons-active(theme-color(success, active), theme-color(success, active));
    }

    &:focus {
      @include buttons-focus(theme-color(success, hover));
    }

    &.disabled {
      @include button-trans(theme-color(success, trans), theme-color(success, trans));
    }
  }

  &-inverse {
    @include buttons(transparent, $theme-border-color, $primary-color);

    &:hover {
      @include buttons-hover(darken($primary-color, 5%), $color-blue, white);
    }

    &:active {
      @include buttons-hover(darken($primary-color, 5%), $color-blue, white);
    }

    &:focus {
      @include buttons(transparent, $primary-color, $primary-color);
    }

    &.disabled {
      @include button-trans(theme-color(inverse, trans), theme-color(inverse, trans));
    }
  }

  &-info {
    @include buttons(theme-color(info, base), theme-color(info, base));

    &:hover {
      @include buttons-hover(theme-color(info, hover), theme-color(info, hover));
    }

    &:active {
      @include buttons-active(theme-color(info, active), theme-color(info, active));
    }

    &:focus {
      @include buttons-focus(theme-color(info, hover));
    }

    &.disabled {
      @include button-trans(theme-color(info, trans), theme-color(info, trans));
    }
  }

  &-disabled {
    @include buttons(theme-color(disabled, trans), theme-color(disabled, trans));
  }

  /**====== Basic-Buttons css end ======**/
  /**====== Outline-Buttons css Start ======**/
  &-outline-primary {
    @include buttons(transparent, $primary-color, $primary-color, none);
    border-width: 1px;
    border-style: solid;
    height: 40px;

    &:hover {
      @include buttons-hover(transparent, $primary-color, $primary-color);
    }
  }

  &-outline-secondary {
    @include buttons($theme-title-bg-color, $color-gray-5, $primary-color, none);
    border-width: 1px;
    border-style: solid;
    height: 40px;

    &:hover {
      @include buttons-hover($theme-title-bg-color, $primary-color, $primary-color);
    }
  }

  &-outline-warning {
    @include buttons-borders(theme-color(warning, active));
    background-color: transparent;
  }

  &-outline-danger {
    @include buttons-borders(theme-color(danger, active));
    background-color: transparent;
  }

  &-outline-success {
    @include buttons-borders(theme-color(success, active));
    background-color: transparent;
  }

  &-outline-inverse {
    @include buttons-borders(theme-color(inverse, active));
    background-color: transparent;

    &:hover {
      color: #fff;
    }
  }

  &-outline-warning {
    @include buttons-borders(theme-color(warning, active));
    background-color: transparent;
  }

  &-outline-info {
    @include buttons-borders(theme-color(info, active));
    background-color: transparent;
  }

  &-outline-disabled {
    @include buttons-borders(theme-color(disabled, active));
  }

  /**====== Outline-Buttons css end ======**/

  /**====== materialized-Buttons css Start ======**/
  &-mat {
    position: relative;
    border-radius: 0;
    border: none;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      right: 50%;
      height: 3px;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      border: none;

      &:before {
        left: 0;
        right: 0;
      }
    }
  }

  &-primary.btn-mat {
    background-color: theme-color(primary, base);

    &:before {
      background-color: theme-color(primary, active);
    }
  }

  &-warning.btn-mat {
    background-color: theme-color(warning, base);

    &:before {
      background-color: theme-color(warning, active);
    }
  }

  &-danger.btn-mat {
    background-color: theme-color(danger, base);

    &:before {
      background-color: theme-color(danger, active);
    }
  }

  &-success.btn-mat {
    background-color: theme-color(success, base);

    &:before {
      background-color: theme-color(success, active);
    }
  }

  &-inverse.btn-mat {
    background-color: theme-color(inverse, base);

    &:before {
      background-color: theme-color(inverse, active);
    }
  }

  &-info.btn-mat {
    background-color: theme-color(info, base);

    &:before {
      background-color: theme-color(info, active);
    }
  }

  &-outline-disabled {
    background-color: theme-color(disabled, base);

    &:before {
      background-color: theme-color(disabled, active);
    }
  }

  /**====== materialized css end ======**/
  /**====== Button-Size css start ======**/
  &-xlg {
    padding: 14px 20px;
    font-size: 21px;
    line-height: 40px;
  }

  &-lg {
    padding: 12px 18px;
    font-size: 18px;
    line-height: 32px;
  }

  &-md {
    padding: 10px 16px;
    font-size: 15px;
    line-height: 23px;
  }

  &-sm {
    padding: 8px 14px;
    line-height: 16px;
    font-size: 11px;
  }

  &-mini {
    padding: 5px 10px;
    line-height: 14px;
    font-size: 10px;
  }

  /**====== Button-Size css end ======**/
  /**====== Badges-button css start ======**/
  &-primary .badge {
    @include labels-badge-inverse(theme-color(primary, base));
  }

  &-success .badge {
    @include labels-badge-inverse(theme-color(success, base));
  }

  &-info .badge {
    @include labels-badge-inverse(theme-color(info, base));
  }

  &-warning .badge {
    @include labels-badge-inverse(theme-color(warning, base));
  }

  &-danger .badge {
    @include labels-badge-inverse(theme-color(danger, base));
  }

  /**====== Badges-button css end ======**/
}

/**====== Theme-Buttons css end ======**/

.btn-disabled {

  &.disabled,
  &:disabled {
    cursor: default;
    opacity: 1;
    box-shadow: none;
    color: $color-disabled !important;
    border: 0;
    pointer-events: none;

    .btn-icon {

      &:hover,
      &:active {
        background: transparent;
        color: $color-disabled !important;
      }
    }
  }
}

.no-border-empty-button {
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  box-shadow: none;
  background: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }

  &.danger {
    svg-icon {
      color: theme-color(danger, base);
    }

    &:hover {
      svg-icon {
        color: theme-color(danger, hover);
      }
    }
  }
}

.ghost-btn {
  @extend .no-border-empty-button;
  padding: 0.5rem 0.75rem;
  text-align: left;
  line-height: 21px;
  color: $theme-font-gray;
  font-weight: 500;

  svg-icon {
    display: flex;
    color: $theme-font-gray;
    min-width: 1rem;
    margin-right: 0.5rem;
  }

  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    color: $theme-font-color;

    svg-icon {
      color: $theme-font-color;
    }
  }

  &.rounded {
    border-radius: 20px !important;
  }

  &.primary {
    color: $primary-color;

    svg-icon {
      color: $primary-color;
    }

    &:active,
    &:hover {
      color: $color-blue-dark;

      svg-icon {
        color: $color-blue-dark;
      }
    }
  }

  &-primary,
  &-primary:focus {
    background: $color-blue-lighter;
    color: $primary-color;

    svg-icon {
      color: $primary-color;
    }

    &:active,
    &:hover {
      background: $primary-color;
      color: $color-white;

      svg-icon {
        color: $color-white;
      }
    }
  }

  @extend .btn-disabled;
}

.icon-btn {
  @extend .no-border-empty-button;
  padding: 12px;

  svg-icon {
    display: flex;
    font-size: inherit;
    color: $theme-font-light;
  }

  &.small {
    padding: 0;
  }

  &:hover:not(:disabled) {
    svg-icon {
      color: $theme-font-color;
    }
  }

  &.disabled {
    svg-icon {
      color: $theme-font-disabled;
    }
  }

  &.warning {
    color: theme-color(warning, base);
  }

  &.danger {
    color: theme-color(danger, base);
  }

  &.primary {
    svg-icon {
      color: $primary-color;
    }

    &:hover {
      svg-icon {
        color: $color-blue-dark;
      }
    }
  }
}

.icon-btn-rounded {
  @extend .icon-btn;
  height: 40px;
  border-radius: 20px;

  svg-icon {
    color: #a9b7d5;
  }

  &:hover,
  &:active,
  &:focus {
    background: $theme-bg-color;

    svg-icon {
      color: $color-gray;
    }
  }

  &.starred {
    svg-icon {
      fill: none;
    }
  }

  &.starred.active {
    svg-icon {
      color: $color-orange-2;

      svg {
        fill: $color-orange-2;
      }
    }
  }

  &.active {
    svg-icon {
      color: $color-blue;

      svg {
        fill: $color-blue;
      }
    }

    &:hover:not(.starred),
    &:active:not(.starred),
    &:focus:not(.starred) {
      svg-icon {
        color: $color-blue;

        svg {
          fill: $color-blue;
        }
      }
    }
  }
}

.circle-btn-icon {
  display: flex;
  justify-content: center;
  min-height: 24px;
  min-width: 24px;
  border-radius: 50%;

  &.small {
    padding: 0;
    max-width: 24px;
    max-height: 24px;
  }

  &.default:not(:disabled) {
    color: $theme-font-light;

    &:hover {
      color: $theme-font-black;
    }
  }

  &.info:not(:disabled) {
    color: $theme-font-light;
    background: $color-gray-6;

    &:hover {
      color: $color-gray-6;
      background: $color-blue-light;
    }
  }

  &.primary:not(:disabled) {
    color: white;
    background: theme-color(primary, base);

    &:hover {
      background: theme-color(primary, hover);
    }
  }

  &.warning:not(:disabled) {
    color: white;
    background: theme-color(warning, base);
  }

  &.danger:not(:disabled) {
    color: white;
    background: theme-color(danger, base);
  }
}