@use './../general/variables' as *;
@use 'include-media' as *;

.utilization {
  display: flex;
  flex-flow: column;
  margin: 0;
  background: white;
  border-radius: $theme-border-radius;

  .metrics-bar {
    display: flex;
    flex-wrap: wrap;
    padding: 24px 12px;
    gap: 16px;
  }

  .used-resources {
    display: flex;
    align-items: center;

    svg-icon {
      height: 16px;
      color: icon-color(warning, base);
    }

    p {
      padding-left: 8px;
    }
  }

  .utilization-chart {
    padding: 12px 4px 16px 12px;
    app-line-chart {
      display: block;
    }
  }

  .filter-info {
    display: inline-flex;
    color: $primary-color;
    margin-left: -8px;
  }

  @include media('>=tablet') {
    .metrics-bar {
      padding: 24px;
    }
    .utilization-chart {
      padding: 12px 16px 24px 20px;
    }
  }

  .mat-mdc-select {
    max-width: 220px;
    min-width: auto;
  }
}
