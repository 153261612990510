.flex,
.flex-row,
.flex-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex
}

.flex-i {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important
}

.flex-row {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.flex-col {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column
}

.flex-between {
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.flex-around {
  -ms-flex-pack: center;
  -webkit-justify-content: space-around;
  justify-content: space-around
}

.flex-justify--start {
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start
}

.flex-justify--end {
  -ms-flex-pack: end;
  -webkit-justify-content: end;
  justify-content: end;
}

.flex-justify--center {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center
}

.flex-justify-right {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end
}

.flex-center {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.flex-stretch {
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

.flex-equal {
  -webkit-box: 1 1 100%;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%
}

.flex-self-center {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center
}

.flex-grow-1 {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1
}

.flex-grow-2 {
  -webkit-flex-grow: 2;
  -ms-flex-positive: 2;
  flex-grow: 2
}

.flex-grow-none {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0
}

.flex-shrink-none {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.flex-shrink--0 {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.flex--1 {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1
}

.flex-auto {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

.flex-none {
  -webkit-flex: 0 0 auto !important;
  -ms-flex: 0 0 auto !important;
  flex: 0 0 auto !important;
}

.flex-full {
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%
}

.flex-three-fourths {
  -webkit-flex: 0 0 75%;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%
}

.flex-fourth {
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%
}

.flex-two-thirds {
  -webkit-flex: 0 0 66.66667%;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%
}

.flex-third {
  -webkit-flex: 0 0 32.333333333333336%;
  -ms-flex: 0 0 32.333333333333336%;
  flex: 0 0 32.333333333333336%
}

.flex-half {
  -webkit-flex: 0 0 49%;
  -ms-flex: 0 0 49%;
  flex: 0 0 49%
}

@media only screen and (max-width:40em) {
  .flex-full-mobile {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%
  }
}

.order--1 {
  order: 1
}

.order--unset {
  order: unset
}

@media only screen and (max-width:40em) {
  .order--unset\@phone {
    order: unset
  }
}

.flex-direction--row {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

@media only screen and (max-width:75em) {
  .flex-direction--row\@desktop {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }
}

@media only screen and (max-width:64em) {
  .flex-direction--row\@tablet {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }
}

@media only screen and (max-width:40em) {
  .flex-direction--row\@phone {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }
}

.flex-direction--column {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column
}

@media only screen and (max-width:75em) {
  .flex-direction--column\@desktop {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

@media only screen and (max-width:64em) {
  .flex-direction--column\@tablet {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

@media only screen and (max-width:40em) {
  .flex-direction--column\@phone {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

.flex-direction--unset {
  -webkit-flex-direction: unset;
  -ms-flex-direction: unset;
  flex-direction: unset
}

@media only screen and (max-width:40em) {
  .flex-direction--unset\@phone {
    -webkit-flex-direction: unset;
    -ms-flex-direction: unset;
    flex-direction: unset
  }
}

.align-content--start {
  -webkit-align-content: flex-start;
  align-content: flex-start
}

.align-content--end {
  -webkit-align-content: flex-end;
  align-content: flex-end
}

.align-content--center {
  -webkit-align-content: center;
  align-content: center
}

.align-items--center {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.align-items--baseline {
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline
}

.align-items--flex-start {
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start
}

.align-items--flex-end {
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end
}

.align-self--flex-start {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start
}

.align-self--flex-end {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: flex-end;
  align-self: flex-end
}

.align-self--center {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center
}

.justify-content--flex-end {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end
}

.justify-content--center {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center
}

.justify-content--space-between {
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.justify-content--unset {
  -ms-flex-pack: center;
  -webkit-justify-content: unset;
  justify-content: unset
}

@media only screen and (max-width:40em) {
  .justify-content--unset\@phone {
    -ms-flex-pack: center;
    -webkit-justify-content: unset;
    justify-content: unset
  }
}

.flex-flow--nowrap {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap
}

.flex-wrap--wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.flex-gap-8-4 {
  column-gap: 8px;
  row-gap: 4px;
}

.flex-gap-16-8 {
  column-gap: 16px;
  row-gap: 8px;
}

.flex-gap-24-8 {
  column-gap: 24px;
  row-gap: 8px;
}

.flex-gap-40-24 {
  column-gap: 40px;
  row-gap: 24px;
}