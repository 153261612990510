@use '../../general/variables' as *;

$col-primary: #1058f5;
$col-primary-400: lighten($col-primary, 10%);
$col-primary-500: $col-primary;
$col-primary-600: darken($col-primary, 10%);

:root {
  --hue: 221;
  --saturation: 92%;
  --lightness: 51%;
  --col-text: $theme-font-color;
  --col-primary-400: #{$col-primary-400};
  --col-primary-500: #{$col-primary-500};
  --col-primary-600: #{$col-primary-600};
  --color-primary: var(--hue), var(--saturation), var(--lightness);
  --mdc-icon-button-icon-size: 12px;
}

$pointee-palette: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: var(--col-primary-400),
  500: var(--col-primary-500),
  600: var(--col-primary-600),
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: rgba($col-primary-500, .9),
  A200: var(--col-primary-500, .2),
  A400: rgba($col-primary-500, .6),
  A700: rgba($col-primary-500, .3),
  contrast: (50: $theme-font-color,
    100: $theme-font-color,
    200: $theme-font-color,
    300: $theme-font-color,
    400: white,
    500: $theme-font-inverse,
    600: $theme-font-inverse,
    700: $theme-font-inverse,
    800: $theme-font-inverse,
    900: $theme-font-inverse,
    A100: $theme-font-header,
    A200: $theme-font-inverse,
    A400: $theme-font-inverse,
    A700: $theme-font-inverse,
  )
);