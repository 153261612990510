@use '../general/variables' as *;

.info-message-box-panel {
  display: flex;
  flex: 1;
  align-items: center;
  background: $color-blue-lighter;
  padding: 1rem 1.5rem;
  border-radius: $theme-border-radius;

  span {
    color: $theme-font-black;

    p {
      padding-bottom: 0;
    }

    &.info-icon {
      height: 16px;

      svg-icon {
        font-size: 16px;
      }
    }

    &.info-message {
      margin-left: 1rem;
    }

    .btn-link {
      padding: 0;
      text-align: left;
    }
  }
}