@use '../../../general/variables' as *;
@use '@angular/material' as mat;
@include mat.checkbox-density(-1);

$checkbox-size: 16px;
$checkbox-touch-target-size: 16px;

:root {
  --mdc-checkbox-state-layer-size: 18px;
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checbox-touch-target: 18px;
  --mdc-checkbox-selected-icon-color: #1058f5;
  // --mdc-checkbox-unselected-icon-color: white;
}

.mat-mdc-checkbox,
.mat-pseudo-checkbox {
  // Avoids issues in some CSS grid layouts (see #25153).
  position: relative;
  // Disable the browser's tap highlight since we indicate state with the ripple instead.
  border-width: 1px;
  -webkit-tap-highlight-color: transparent;
  --mdc-checkbox-selected-checkmark-color: white !important;

  .mat-mdc-checkbox-touch-target {
    height: 100%;
    width: 100%;
  }

  .mdc-checkbox {
    padding: 0 !important;
    margin: 0 !important;
    flex: 0 0 16px !important;

    .mdc-checkbox__background {
      width: $checkbox-size;
      height: $checkbox-size;
      top: 1px !important;
      left: 0 !important;
    }
  }

  .mdc-form-field {
    margin: 0 !important;
    width: 100%;

    label {
      margin-bottom: 0;
    }
  }

  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true']) ~ .mdc-checkbox__background {
    background-color: white !important;
  }

  .mdc-checkbox__ripple {
    display: none;
  }

  label {
    flex: auto;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding-left: 12px !important;
    cursor: pointer;
  }

  .item-info-count {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: $color-blue-lighter;
    text-align: center;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.mat-pseudo-checkbox,
.mat-mdc-option .mat-pseudo-checkbox {
  width: $checkbox-size;
  height: $checkbox-size;
  margin-right: 8px !important;

  &::after {
    top: 2.3px;
    width: 8px;
    height: 3px;
  }
}
