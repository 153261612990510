.empty-text-container {
  display: flex;
  flex-flow: column;
  margin: 40px 0;
  max-width: 344px;
  justify-content: center;

  p {
    font-size: 16px; // correct -  this should be 16px
    text-align: center;
  }
}