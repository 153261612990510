@use '../general/variables' as *;
@use '../overrides/bootstrap/button' as *;

.header-search-panel {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex: 1;
  gap: 8px;

  &.no-margin-padding {
    justify-content: flex-start;
  }

  button {
    margin-right: 8px;
  }

  h2 {
    line-height: 40px;
    max-width: 100%;
    word-wrap: anywhere;
  }

  .btn-search-icon {
    @extend .icon-btn;
    height: 40px;
    border-radius: 20px;

    &:hover:not(:disabled) {
      svg-icon {
        color: $theme-font-black;
      }
    }

    &.selected {
      svg-icon {
        color: $primary-color;
      }

      background: theme-color(primary, trans);
    }
  }

}