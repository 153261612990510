@use 'include-media' as *;
@use '../theme' as *;
@use '../../../general/variables' as *;

$table-header-row-height: 40px;
$table-row-height: 48px;
$table-cell-padding: 16px;

.table-container {
  width: 100%;
  padding: 12px;
  overflow: auto;

  @include media('>=tablet') {
    padding: 24px;
  }
}

.table-container-flex {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  margin: 0;
  padding: 0;
}

.table-view-more {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

// global styles
.mat-mdc-table {
  font-family: $theme-font-roboto-family;
  white-space: nowrap !important;
  border-collapse: separate;
  table-layout: fixed !important;

  &.auto {
    table-layout: auto !important;
  }

  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    padding: 0 $table-cell-padding 0 0;
    color: $theme-font-header;

    svg-icon {
      vertical-align: middle;
      color: $theme-font-gray;
    }

    .primary:not(:disabled) {
      svg-icon {
        color: $primary-color;
      }
    }

    :disabled {
      background-color: transparent;
    }
  }

  .mdc-data-table__cell:first-of-type,
  .mdc-data-table__header-cell:first-of-type,
  .mdc-data.table__footer-cell:first-of-type {
    padding-left: 0;
  }

  .mdc-data-table__cell:last-of-type,
  .mdc-data-table__header-cell:last-of-type,
  .mdc-data.table__footer-cell:last-of-type {
    padding-right: 0;
  }

  .mdc-data-table__header-row {
    height: $table-header-row-height;
  }

  .mdc-data-table__row {
    height: $table-row-height;
    background-color: white;

    &:last-child .mdc-data-table__cell {
      // border-bottom: $mat-table-border-color 1px solid; // remove border from last row
    }

    &:hover {
      .mdc-data-table__cell {
        background-color: $table-hover-bg;
      }
    }

    &.disabled {
      .mdc-data-table__cell {
        color: $theme-font-gray;
      }
    }
  }

  .mat-mdc-header-row,
  .mat-mdc-row {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    overflow: hidden;
  }

  .mat-mdc-header-cell,
  .mat-mdc-cell {
    display: inline-flex;
    flex: 1 1 0;
    align-items: center;
    height: 100%;
    min-width: 0;

    &.align-center {
      text-align: center;
      justify-content: center;

      button {
        margin: 0 auto;
      }

      .mat-sort-header-container {
        justify-content: center;
      }
    }

    &.align-right {
      text-align: right;
      justify-content: flex-end;

      .mat-sort-header-container {
        justify-content: flex-end;
      }
    }

    &.w-col-auto {
      flex: 0 0 auto;
      width: auto;
    }

    &.w-col-1 {
      flex: 0 0 8.3333% !important;
      width: 8.3333%;
    }

    &.w-col-2 {
      flex: 0 0 16.6666% !important;
      width: 16.6666%;
    }

    &.w-col-3 {
      flex: 0 0 25% !important;
      width: 25%;
    }

    &.w-col-4 {
      flex: 0 0 33.3333% !important;
      width: 33.3333%;
    }

    &.w-col-5 {
      flex: 0 0 41.6666% !important;
      width: 41.666%;
    }

    &.w-col-6 {
      flex: 0 0 50% !important;
      width: 50%;
    }

    &.w-5 {
      flex: 0 0 5% !important;
      width: 5%;
    }

    &.w-10 {
      flex: 0 0 10% !important;
      width: 10%;
    }

    &.w-15 {
      flex: 0 0 15% !important;
      width: 15%;
    }

    &.w-col-button {
      min-width: 40px;
      max-width: 40px;

      svg-icon {
        min-width: 16px;
      }
    }

    &.overflow-ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      div,
      span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &.flex-wrap {
      flex-wrap: wrap;
    }
  }

  .mat-mdc-header-row {
    .mat-sort-header {
      .mat-sort-header-sorted {
        color: black;
      }
    }

    .mat-mdc-header-cell {
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      color: $theme-font-gray;
      letter-spacing: 0.214px;

      &.no-ellipsis {
        .mat-sort-header-container {
          .mat-sort-header-content {
            overflow: visible !important;
            text-overflow: inherit !important;
            white-space: wrap !important;

            span {
              overflow: visible !important;
              text-overflow: inherit !important;
              white-space: wrap !important;
            }
          }
        }
      }

      a {
        display: block;
        width: 100%;
        color: $theme-font-gray;
        font-weight: normal;
      }

      .mat-sort-header-container {
        position: relative;
        max-width: 100%;

        .mat-sort-header-content {
          max-width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          span {
            display: inline-block;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .mat-sort-header-arrow {
          display: block;
          position: absolute;
          right: -16px;
        }
      }

      &.mat-sort-header-button {
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }

  &.editable-rows {
    .mat-mdc-row:hover:not(.disabled) {
      cursor: pointer;

      .edit-icon {
        display: block;
      }
    }
  }

  .edit-icon {
    display: none;
  }
}