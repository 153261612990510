@use 'include-media' as *;
@use '../../../general/variables' as *;

.mat-drawer-container {
  z-index: $z-index-drawer;

  .mat-drawer-backdrop {
    position: fixed;

    &.mat-drawer-shown {
      background: rgba(60, 64, 73, 0.72);
      color: white;
      cursor: pointer;
    }
  }
}
