@use '../general/variables' as *;

full-calendar {
  a {
    color: $theme-font-color !important;
  }

  .fc-timegrid-body * {
    line-height: initial;
  }

  .fc-day-today {
    background-color: $color-blue-lighter !important;
  }

  .fc-non-business {
    background-color: #e6ebeb66 !important;
  }

  .fc-event-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 8px !important;
  }
}
