@use '../general/variables' as *;

.nav-item {
  position: relative;
  margin: 0 4px;
  border-radius: $theme-border-radius;
  z-index: 99;

  a,
  button {
    color: $theme-font-gray;
  }

  a,
  button {
    padding: 4px 12px;
  }

  &.selected:not(&.disabled) {
    background-color: $color-blue-lighter;

    a,
    button {
      color: $primary-color;
    }
  }

  &.disabled {
    pointer-events: none;

    a,
    button {
      // color: $color-disabled;
      cursor: default;
      pointer-events: none;
      background: transparent;
      color: $color-disabled;
    }
  }

  .btn-ghost-primitive {

    &:hover,
    &:active,
    &.active,
    &:focus {
      color: $primary-color;
    }
  }

  .item-menu-button {
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      background-color: $color-gray-6;
    }
  }

  .nav-item-menu {
    position: absolute;
    display: flex;
    flex-flow: column;
    left: auto;
    right: 0;
    width: 17rem;
    max-height: calc(100vh - 84px);
    padding: 1rem 0;
    background: white;
    box-shadow: $theme-box-shadow;
    border-radius: $theme-border-radius;
    opacity: 0;
    visibility: hidden;
    transform: translateY(8px);

    &.display {
      opacity: 1;
      visibility: visible;
    }

    &:before {
      content: '';
      border: 10px solid transparent;
      border-left-color: #fff;
      border-top-color: #fff;
      position: absolute;
      top: -9px;
      right: 12px;
      transform: rotate(45deg);
      z-index: 802;
    }

    .scroll-list {
      overflow: auto;
    }

    li,
    .nav-item-menu-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      line-height: initial;
      padding: 0.75rem 1.5rem;
      width: 100%;
      transition: all 0.3s ease-in-out;

      &.selected {
        font-weight: 700;
      }

      svg-icon {
        margin-right: 12px;
      }
    }

    .profile-content {
      align-items: flex-start;
      flex-flow: column;
      padding: 0.5rem 1.5rem 1rem;
      border-bottom: 1px solid $theme-border-color;

      h4 {
        font-size: $theme-font-size;
      }

      .version {
        color: $theme-font-light;
      }
    }
  }
}

.tabs {
  .nav-item {
    margin: 0;
  }
}