@use '../general/variables' as *;
@use 'include-media' as *;

$panel-padding: 16px;

.panel {
  display: flex;
  flex-flow: column;
  background: $theme-bg-white;
  border-radius: 4px;
  overflow: hidden;

  &.active {
    box-shadow: $theme-box-shadow;

    &:hover {
      box-shadow: $theme-box-shadow-hover;
    }
  }

  hr {
    border-top: 1px solid $theme-border-color-light;
    margin: 0;

    &.m-t-b-16 {
      margin: 16px 0;
    }

    &.darker {
      border-top: 1px solid $color-gray-5;
    }

    &.gray {
      border-top: 1px solid $color-gray;
    }
  }

  .panel-section {
    display: flex;
    flex-flow: column;
    padding: $panel-padding;
    background: $theme-bg-white-transparent;
    border-radius: $theme-border-radius;

    &.bg-gray {
      background: $theme-title-bg-color;
    }

    &.p-24 {
      padding: 24px;
    }

    &.p-big {
      padding: 24px 12px;
    }

    h4 {
      line-height: 24px;
    }

    &.sub-content-container {
      border-top: 1px solid $color-gray-5;
      background: $theme-border-color-light;
    }

    @include media('>=tablet') {
      flex-flow: row;

      &.column {
        flex-flow: column;
      }

      &.content-container {
        padding-top: 24px;
        padding-bottom: 24px;
      }

      &.p-big {
        padding: 32px 24px;
      }
    }
  }
}

.absolute-panel {
  position: absolute;
  display: flex;
  flex-flow: column;
  z-index: 99;
  overflow: visible;

  &.top-right {
    top: 0;
    right: 0;
  }

  &.right {
    right: 0;
  }

  &.top-left {
    top: 16px;
    left: 16px;

    @include media('>=tablet') {
      top: 32px;
      left: 32px;
    }
  }

  .panel-item {
    display: flex;
    flex-flow: column;
    margin: 0 0 1rem 0;
  }

  .panel-item:last-child {
    margin-bottom: 0;
  }
}