@use '../../../general/variables' as *;

mat-form-field,
.mdc-form-field,
.mat-mdc-form-field {
  display: flex;
  line-height: 24px;

  &.form-control {
    display: block;
    width: auto;
    height: auto;
    padding: 0;
    font-weight: inherit;
    line-height: inherit;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-wrapper {
    margin: 0;
    padding: 0;

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-flex {
      border-radius: 0;
      padding: 0;
      margin-top: 0;
      background: none !important;
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-infix {
    display: flex;
    border: none !important;
    padding: 0;
  }
}

input.mat-mdc-input-element {
  margin: 0;
}

.mat-form-field-appearance-outline {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-wrapper {
    margin: 0;
    padding: 0;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-outline {
    color: $theme-border-color;
    top: 0 !important;

    &:hover,
    &:active {
      color: $theme-border-color-active;
    }
  }
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  flex-wrap: wrap;
  max-width: fit-content;
  border: 1px solid $theme-border-color;
  border-radius: $theme-border-radius;
  background: $theme-title-bg-color;
}

.mat-button-toggle {
  line-height: $input-line-height;
  height: $input-height;
  background: transparent;
  color: $theme-font-light;
  border: 0;
  font-weight: 500;
  overflow: hidden;

  .mat-button-toggle-button {
    height: 100%;
  }

  &:hover {
    color: $theme-font-color;
  }

  &.mat-button-toggle-checked {
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid $color-blue;
      border-radius: $theme-border-radius;
      background: transparent;
      z-index: 1;
    }

    // border: 1px solid $primary-color !important;
  }

  &.mat-button-toggle-disabled {
    color: $theme-font-disabled;
    // background-color: $theme-title-bg-color;

    &:hover {
      color: $theme-font-disabled;
    }

    &.mat-button-toggle-checked {
      border: none !important;
    }
  }

  .mat-button-toggle-focus-overlay {
    opacity: 0 !important;
  }
}

.mat-button-toggle-label-content {
  padding: 0 16px;
  vertical-align: middle;
  // ellipsis
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

mat-error {
  display: block;
  font-size: 12px;
  color: #f44336;
  line-height: 21px;
}