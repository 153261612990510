@use '../general/variables' as *;
@use 'include-media' as *;

.performance-item-container {
  display: flex;
  flex-flow: column;

  @include media('<tablet') {
    gap: 16px;
  }
}

.performance-item-half {
  @include media('>=tablet') {
    min-width: 50%;

    .performance-item-container {
      flex-flow: row;
    }
  }
}

.performance-item {
  display: flex;
  flex-flow: row;

  strong {
    font-size: 20px;
    font-weight: 700;
  }

  .inline-value {
    display: flex;
    align-items: flex-end;
    line-height: 24px;
    color: $theme-font-gray;

    p {
      text-overflow: ellipsis;
      margin-bottom: 0;
      line-height: inherit;
    }

    b {
      font-size: 16px;
      line-height: 22px;
      font-weight: 700;
    }

    shared-visual-trend {
      line-height: inherit;
    }

    @include media('<tablet') {
      &.metrics-label::after {
        display: inline-flex;
        content: ':';
        padding-right: 4px;
      }
    }

    @include media('>=tablet') {
      flex: 1;

      b {
        font-size: 20px;
        line-height: 28px;
      }

      &.metrics-line {
        line-height: 32px;
      }
    }
  }

  @include media('>=tablet') {
    flex-flow: column;
    flex: 1;
    // max-width: 50%;
  }
}

.pending-item {
  svg-icon {
    margin-right: 8px;
  }
}