@use 'include-media' as *;

$margin: 16px;
$gutter-x: 16px;
$column-count: 12;

:root {
  --margin: #{$margin};
  --gutter-x: #{$gutter-x};
  --column-count: #{$column-count};
  --m: var(--margin);
  --g: var(--gutter-x);
  --c: 1fr;
  --container-grid-template: 3fr 3fr 3fr 3fr;
  --grid-template-areas: "c1";
}

.grid {
  display: grid;
  grid-row-gap: 12px;

  @include media('>=phone') {
    grid-template-columns: repeat(6, [col] 1fr);
  }

  @include media('>=tablet') {
    grid-template-columns: repeat(12, [col] 1fr);
    grid-row-gap: 0;
  }
}

// Grid columns generic classes
@for $i from 1 through $column-count {
  .grid-col-#{$i} {
    grid-column: span $i;
  }
}