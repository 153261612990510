@use '@angular/material' as mat;

:root {
  --mat-option-selected-state-label-text-color: #020c27;
  --mat-standard-button-toggle-hover-state-layer-opacity: .08;
  --mat-standard-button-toggle-focus-state-layer-opacity: .12;
  --mat-standard-button-toggle-text-color: #77787b;
  --mat-standard-button-toggle-state-layer-color: #77787b;
  --mat-standard-button-toggle-selected-state-background-color: white;
  --mat-standard-button-toggle-selected-state-text-color: #1058f5;
  --mat-standard-button-toggle-disabled-state-text-color: #b3b3b3; //$color-disabled:
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(26, 27, 31, .38);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #f5f7f7;
  --mat-standard-button-toggle-divider-color: #ccd4e6;
  --mat-standard-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 14px;
  --mat-standard-button-toggle-label-text-tracking: .006rem;
  --mat-standard-button-toggle-label-text-weight: 500;
  --mat-standard-button-toggle-height: 40px;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-background-color: #f5f7f7;
  --mat-standard-button-toggle-disabled-state-background-color: #f5f7f7;
}

.mat-button-toggle-group-appearance-standard {
  border-radius: var(--mat-standard-button-toggle-shape);
}