@use '../general/variables' as *;

.pointee-input {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 42px;

  input {
    width: 100%;
    min-width: 6em;
    max-width: 100%;
    padding: 12px;
    font-family: $theme-font-roboto-family;
    color: $theme-font-black;
    font-weight: normal;
    border: 1px solid $theme-border-color;
    border-radius: 4px;
    transition: all 0.2s;

    &:active,
    &:focus,
    &:hover {
      border: 1px solid $theme-border-color-active;
      box-shadow: none;
    }

    &:disabled {
      background-color: #eee;
      border: 1px solid #ddd;
      color: $disabled-color;
    }

    &.invalid {
      border: 1px solid $danger-color;
    }

    &.text,
    &.number {
      text-align: left;
    }

    &.currency {
      text-align: right;
      padding-right: 1.3rem;
    }
  }

  input~label {
    display: flex;
    position: absolute;
    left: 0;
    top: -1.6em;
  }
}