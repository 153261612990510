@use '../../../general/variables' as *;

.mat-expansion-panel {
  box-shadow: none !important;

  .mat-expansion-panel-header {
    height: 42px;
    padding: 0;
    font-weight: 500;
    font-size: 14px;
    color: $theme-font-header;

    &:hover {
      background: transparent !important;
    }

    .mat-expansion-panel-header-title {
      color: $theme-font-header;
    }

    .mat-expansion-panel-header-description {
      flex-grow: 0;
      font-style: italic;
      font-weight: 400;
      color: $theme-font-gray;
    }

    .mat-expansion-indicator {
      margin-right: 2px;

      &::after {
        color: $theme-font-gray;
      }
    }
  }

  .mat-expansion-panel-spacing {
    margin: 0;
  }

  .mat-expansion-panel-body {
    padding: 0 0 4px;
  }
}
