@use '../../../general/variables' as *;

:root {
  --mat-menu-item-label-text-weight: 400;
}

.mat-mdc-menu-panel {
  box-shadow: none;
  padding: 0;
  background: white;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
  min-height: 36px !important;
  min-width: 230px !important;

  .mat-mdc-option {

    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-search-clear {
      display: flex;

      &.mat-mdc-button-base {
        width: 24px;
        height: 24px;
        padding: 9px 0;
      }
    }

    svg-icon {
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .mat-mdc-button-persistent-ripple,
    .mat-mdc-button-touch-target {
      display: none;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    &.contains-mat-select-search {
      height: 40px;
      margin-top: 0 !important;
    }

    &.mat-mdc-selected:not(.mat-mdc-option-multiple) {
      font-weight: 700;
      color: black;
      text-shadow: none;
      background: transparent;
    }

    &:hover {
      background: $theme-bg-color;
      text-shadow: none;
    }
  }

  a.mat-mdc-menu-item,
  button.mat-mdc-menu-item {
    display: flex;
    align-items: center;
    color: $theme-font-header;
    background: $action-color !important;
    font-family: $theme-font-roboto-family;
    max-height: 40px;
    min-height: 40px;
    opacity: 1;

    span {
      display: flex;
      gap: 12px;
      font-weight: 400;
      color: $theme-font-header;
    }

    svg-icon {
      display: inline-flex;
      font-size: 16px;
      color: $color-gray;
    }

    &:hover:enabled,
    &:hover:not(.disabled) {
      span {
        color: $theme-font-black;
        font-weight: 500;
      }

      svg-icon {
        color: $theme-font-black;
      }

      background: none;
      text-shadow: none;
    }

    &.danger {
      color: $danger-color;

      svg-icon {
        color: $danger-color;
      }

      &:hover:enabled,
      &:hover:not(.disabled) {
        color: darken($danger-color, 12%) !important;

        svg-icon {
          color: darken($danger-color, 12%) !important;
        }
      }
    }

    &:disabled,
    &.disabled {
      pointer-events: none;

      span {
        color: $theme-font-disabled;
      }

      svg-icon {
        color: $theme-font-disabled;
      }
    }
  }
}
