html {
  --scrollbar-color-thumb: #aaa; // hotpink is super cool :-D otherwise #aaa is boring
  --scrollbar-color-track: transparent;
  --scrollbar-width: thin;
  --scrollbar-width-legacy: 8px;

  // scrollbar-gutter: stable;

  /* Legacy browsers with `::-webkit-scrollbar-*` support */
  @supports selector(::-webkit-scrollbar) {
    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: var(--scrollbar-color-thumb);
    }

    ::-webkit-scrollbar-track {
      background: var(--scrollbar-color-track);
    }

    ::-webkit-scrollbar {
      max-width: var(--scrollbar-width-legacy);
      max-height: var(--scrollbar-width-legacy);
      width: var(--scrollbar-width);
    }
  }

  /* Standardized Properties */
  @supports not selector(::-webkit-scrollbar) {
    scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
    scrollbar-width: var(--scrollbar-width);
  }

  .scrollable {
    &:hover::-webkit-scrollbar-thumb {
      background: var(--scrollbar-color-thumb);
    }

    &:hover::-webkit-scrollbar-track {
      background: var(--scrollbar-color-track);
    }

    &:hover {
      --fix: ;
      /* This custom property invalidates styles on hover, thereby enforcing a style recomputation. This is needed to work around a bug in Safari. */
    }
  }
}