@use '../general/variables' as *;

.data-error-wrapper {
  display: flex;
  max-width: 420px;
}

.error-content {
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: 16px;
  text-align: center;

  .error-content-header {
    display: flex;
    flex-flow: column;
    gap: 12px;
  }

  p {
    font-size: 16px;
  }

  svg-icon {
    color: $theme-font-gray;
    font-size: 48px !important;
  }

  button {
    margin-top: 4px;
  }

  button:not(.btn-link) {
    align-self: center;
  }

  .btn-link {
    display: flex;
    max-height: 48px;
    width: auto;
    padding: 12px 24px;
  }

  &.narrow {
    max-width: 350px;
  }
}