.modal-backdrop {
  z-index: 1040 !important;
}

.modal-body {
  padding: 0 !important;
}

.modal-footer-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}
